import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import { Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeHeader from './HomeHeader';
import { getTitle } from './helper';
import OrdersHeader from './OrdersHeader';
import OrdersHistoryHeader from './OrdersHistoryHeader';
import MasterMenuHeader from './MasterMenuHeader';
import MasterMenuTabs from './MasterMenuTabs';
import HeaderTabs from 'components/Chargeback/HeaderTabs';
import ResumePauseButton from 'components/PauseResume/ResumePauseButton';
import BusinessNewListBox from 'components/BusinessListNew';
import TabsTable from 'pages/TableOrdering/TabsTable/TabsTable'; // Corrected the path if 'TabsTable' was a typo
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { checkHasAnyConnectedProvider, isTableOrderingProvider } from 'utils/checkAccessDenied';
import { useTranslation } from 'react-i18next';
import useUpdateBusiness from 'hooks/useUpdateBusiness';
import ReportView from 'components/Order/ReportView';
const drawerWidth = 240;

// Styled AppBar using Material-UI's styled API
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: 'white',
  position: 'static', // Removed '!important' for better maintainability
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ handleDrawerOpen, open }) => {
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const providers = useSelector((state) => state.providers);
  const isTableOrdering = isTableOrderingProvider(providers?.items);
  const hasConnectProvider = checkHasAnyConnectedProvider(providers?.items);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    business: { currentBusiness },
  } = useSelector((state) => state);
  const checkPlan = currentBusiness?.settings?.disputeSettings?.enabled;

  useUpdateBusiness();

  const { pathname } = location;

  const renderHeader = () => {
    const renderDefaultHeader = (title) => (
      <Box sx={{ display: 'flex', alignItems: 'center', pt: "8px" }}>
        <IconButton
          id="masterMenuHeaderMenu"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ color: '#3D4350', fontSize: '20px' }}>
          {title}
        </Typography>
      </Box>
    );

    switch (true) {
      case pathname.includes('/onboarding'):
        return (
          <Box>
            <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>
              Onboarding
            </Typography>
          </Box>
        );

      case pathname === '/':
        return <HomeHeader handleDrawerOpen={handleDrawerOpen} open={open} />;

      case pathname.includes('/orders'):
        return <OrdersHeader handleDrawerOpen={handleDrawerOpen} open={open} />;

      case pathname.includes('/history'):
        return (
          <OrdersHistoryHeader handleDrawerOpen={handleDrawerOpen} open={open} />
        );

      case pathname.includes('/menu'):
        return <MasterMenuHeader handleDrawerOpen={handleDrawerOpen} open={open} />;

      case isTableOrdering && pathname.includes('/table-ordering'):
        return renderDefaultHeader('Table Ordering');

      case pathname.includes('/chargeback'):
        return renderDefaultHeader('Chargeback');

      default:
        return renderDefaultHeader(t(getTitle(pathname)));
    }
  };


  return (
    <AppBar
      id="header-wrapper"
      open={open}
      elevation={0}

      sx={{
        boxSizing: 'border-box',
        boxShadow: 'none',
        minHeight: "70px"
      }}
    >
      <Toolbar id="header-toolbar" sx={{
        px: "8px",
        py: "8px",
        gap: "8px",
        borderBottom: '1px solid #bcbec3',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
      }}>
            {renderHeader()}
          <Box
            sx={{
              width: '100%',
              maxWidth: isMobile || isTablet ? "100%" : '750px',
              flexWrap: isMobile || isTablet ? "wrap" : "nowrap"
            }}
            display="flex"
            justifyContent={`${isMobile || isTablet ? "start" : "end"}`}
            alignItems="center"
            gap={2}
            my={1}
          >
            {isTypeSingle && currentBusiness?.roles?.name !== 'user' ? <ReportView /> : ''}
            {isTypeSingle && <ResumePauseButton hasConnectProvider={hasConnectProvider} />}
            {/* Uncomment and implement if needed */}
            {/* <BusinessAutocomplete /> */}
            <BusinessNewListBox />
          </Box>
      </Toolbar>
      {/* Conditionally render additional components based on the current path */}
      {/* {pathname.includes('/history') && <OrdersHistoryFilter />} */}
      {pathname.includes('/chargeback') && checkPlan && <HeaderTabs />}
      {pathname.includes('/table-ordering') && <TabsTable />}
      {pathname.includes('/menu') && <MasterMenuTabs />}
    </AppBar>
  );
};

export default Header;
