// src/components/ContactInfo.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * ContactInfo Component
 * Displays the contact information of a business.
 *
 * Props:
 * - report (object): Contains contact details like businessName, emailAddress, phoneNumber, and businessAddress.
 */
const ContactInfo = ({ report }) => {
    const { t } = useTranslation();

    return (
        <Box className="break-avoid mb-4">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                {t('batch_report.contact_info')}
            </Typography>
            <table className="report-contacts-table">
                <tbody>
                    <tr>
                        <td>{t('Name')}:</td>
                        <td>{report.businessName}</td>
                    </tr>
                    <tr>
                        <td>{t('Email')}:</td>
                        <td>{report.emailAddress}</td>
                    </tr>
                    <tr>
                        <td>{t('Phone')}:</td>
                        <td>{report.phoneNumber}</td>
                    </tr>
                    <tr>
                        <td>{t('Address')}:</td>
                        <td>{report.businessAddress}</td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

// Define PropTypes for type checking
ContactInfo.propTypes = {
    report: PropTypes.shape({
        businessName: PropTypes.string.isRequired,
        emailAddress: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        businessAddress: PropTypes.string.isRequired,
    }).isRequired,
};

export default ContactInfo;
