import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayReRange } from 'services/helpers';
import { reorderCategories } from 'redux/actions/Menu/categories';
import SortableCategoryItem from 'components/MenuNew/SortableCategoryItem';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { SET_MENUS_V2 } from 'redux/actions/types';

export default function NestedList({ searchQuery }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { menu, loading, loadingProducts } = useSelector(({ menu }) => menu);
  const [filteredData, setFilteredData] = useState([]);

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const arr1 = menu.categories.map((item) => item._id);
        const arr2 = arrayReRange(arr1, oldIndex, newIndex);
        dispatch(reorderCategories(arr2));
      }
    },
    [dispatch, menu]
  );

  const searchBar = useCallback(
    (data) => {
      setFilteredData(data);
    },
    [searchQuery]
  );

  return (
    <List>
      <div className="menu-categories-body">
        {loading && <Preloader overlay={true} />}
        <SortableList onSortEnd={handleSortEnd} useDragHandle>
          {searchQuery && !filteredData?.length ? (
            <Typography align="center" sx={{ p: 4 }}>
              {t('menu.product_category_results')}
            </Typography>
          ) : (
            <></>
          )}
          {menu.categories?.length ? (
            menu.categories.map((category, index) => {
              return (
                <SortableCategoryItem
                  setFilteredData={searchBar}
                  key={`item-${category._id}`}
                  indexCat={index}
                  index={index}
                  menu={menu}
                  category={category}
                  loading={loading}
                  loadingProducts={loadingProducts}
                  searchQuery={searchQuery}
                />
              );
            })
          ) : (
            <Typography sx={{ p: 4 }} align="center">
              {t('menu.there_is_categories')}
            </Typography>
          )}
        </SortableList>
      </div>
    </List>
  );
}

const SortableList = SortableContainer(({ children, vertical = true }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row', // Use the prop internally
    /* other styles */
  }} className="menu-categories-list">
    {children}
  </Box>
));
