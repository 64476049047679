import React, { useMemo } from 'react';
import OrdersFilter from '../Order/OrdersFilter';
import qs from 'qs';
import { useLocation, useHistory, } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { getPeriods, ORDERS_FILTER_STATUS, INITIAL_PERIOD, INITIAL_STATUS } from 'constants/config';
import { getTitle } from './helper';

import { useTranslation } from 'react-i18next';

const OrdersHeader = ({ handleDrawerOpen, open }) => {
  const { search, pathname } = useLocation();
  const periods = useMemo(() => getPeriods(), []);
  const history = useHistory();
  const { t } = useTranslation();
  const handleFilterChange = (type, value) => {
    history.replace({ search: qs.stringify({ ...query, [type]: value }), pathname: `/orders` });
  };

  const query = useMemo(() => {
    const { status, period, order } = qs.parse(search, { ignoreQueryPrefix: true });
    return {
      status: ORDERS_FILTER_STATUS[status] ? status : INITIAL_STATUS,
      period: periods[period] ? period : INITIAL_PERIOD,
      order,
    };
  }, [periods, search]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // width: '100%',
        flexWrap: 'wrap',
        pt: "8px"
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>{t(getTitle(pathname))}</Typography>
        <OrdersFilter query={query} onSelect={handleFilterChange} />
      </Box>
    </Box>
  );
};

export default OrdersHeader;
