// ImageUpload.js
import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Preloader from 'components/Preloader';
import { unlinkImage } from 'redux/actions/file';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import CropPhoto from '../ImageUpolad/CropPhoto';
import { useHistory } from 'react-router-dom';
import { MIN_IMAGE_UPLOAD_HEIGHT, MIN_IMAGE_UPLOAD_WIDTH } from 'constants/file';
import PropTypes from 'prop-types';

const ImageUpload = ({
  name,
  initialValue,
  removable,
  size,
  disabled,
  className = '',
  model,
  setFormValue = () => { },
  setImage = () => { },
  setDisable = () => { },
  setModel = () => { },
  isBackground = false,
  height = '98',
  width = '98',
}) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const isBusiness = history.location.pathname.includes('/settings/business');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue || { url: '' }); // Initialize with { url: '' }
  const inputRef = useRef();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [imageOptions, setImageOptions] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const handleClose = () => {
    setOpenCropModal(false);
    setImageOptions(null);
    setError(false);
  };

  const handleValidateImageSize = (image) => {
    const reader = new FileReader();
    const img = new Image();
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const isInvalidSize = imgWidth < MIN_IMAGE_UPLOAD_WIDTH || imgHeight < MIN_IMAGE_UPLOAD_HEIGHT;
      setOpenCropModal(true);
      setError(isInvalidSize);
    };
    reader.onloadend = () => {
      setImageSrc(reader.result);
      img.src = reader.result;
    };
    reader.readAsDataURL(image);
  };

  const handleImageCropOpen = (image) => {
    setError(false);
    setOpenCropModal(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(image);
  };

  const handleChange = ({ target: { files } }) => {
    const image = files[0];
    if (image) {
      const imageOptions = {
        name: image.name,
        type: image.type,
        path: URL.createObjectURL(image),
      };
      setImageOptions(imageOptions);
      isBusiness ? handleImageCropOpen(image) : handleValidateImageSize(image);
    }
    if (inputRef.current) {
      inputRef.current.value = ''; // Reset the input value to allow re-uploading the same file
    }
  };

  const handleDelete = () => {
    unlinkImage(value.fileId || value.imageId).then(() => {
      setImage('');
      setValue({ url: '' }); // Set to { url: '' } instead of ''
      if (name === 'avatar') {
        setFormValue('avatar', { fileId: '', url: '' }, { shouldDirty: true });
      } else {
        setFormValue('image', { fileId: '', url: '' }, { shouldDirty: true });
      }
      setDisable(false);
    });
  };

  useEffect(() => {
    setValue(initialValue || { url: '' }); // Ensure value is always an object with url
  }, [initialValue]);

  return (
    <div
      className={cs('image-upload', className, {
        disabled,
        empty: !value?.url,
      })}
      style={size ? { width: `${!isBackground ? size : 400}px`, height: `${!isBackground ? size : 225}px` } : {}}
    >
      <TextField value={value?.url || ''} hidden type="text" name={name} />
      <label>
        {!!value?.url && <img src={`${value?.url}?tr=w-${width},h-${height}`} alt="" />}
        <input
          type="file"
          onChange={handleChange}
          disabled={disabled}
          accept="image/png, image/jpeg"
          ref={inputRef}
        />
        <span className={cs({ 'image-loading': loading, empty: !value?.url })}>
          {loading ? <Preloader /> : <i className="icon icon-camera"></i>}
        </span>
      </label>
      {!disabled && removable && !!value?.url && (
        <IconButton
          sx={{
            background: 'red',
            color: 'white',
            padding: '5px',
            position: 'absolute',
            borderRadius: '5px',
          }}
          color="error"
          variant="contained"
          size="small" // Changed from 'sm' to 'small' for MUI compatibility
          onClick={handleDelete}
          className="delete-image p-1"
        >
          <i className="icon icon-trash-2"></i>
        </IconButton>
      )}
      {openCropModal && (
        <CropPhoto
          photoURL={imageSrc}
          setImageSrc={setImageSrc}
          setFormValue={setFormValue}
          setPhotoURL={() => { }}
          openCropModal={openCropModal}
          title={
            name === 'avatar'
              ? 'settings.business_logo'
              : name === 'image' && !isBusiness
                ? 'menu.product_photo'
                : 'settings.cover_photo'
          }
          className={'logo-content'}
          imageOptions={imageOptions}
          handleClose={handleClose}
          setValue={setValue}
          name={name}
          error={error}
          setError={setError}
        />
      )}
    </div>
  );
};

// Define PropTypes for better type checking and to prevent undefined props
ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.shape({
    url: PropTypes.string,
    fileId: PropTypes.string,
    imageId: PropTypes.string,
  }),
  removable: PropTypes.bool,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  model: PropTypes.object,
  setFormValue: PropTypes.func,
  setImage: PropTypes.func,
  setDisable: PropTypes.func,
  setModel: PropTypes.func,
  isBackground: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImageUpload;
