import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import OrdersHistoryFilter from 'components/Order/OrdersHistoryFilter';
import { getOrdersHistory } from 'redux/actions/orders';
import useQuery from 'hooks/useQuery';
import filterIcon from 'assets/icons/filterIcon.svg';
import { ORDERS_FILTER_STATUS_HISTORY } from 'constants/config';
import {
  ORDERS_HISTORY,
  SET_HEADER_IS_TOUCHED,
  CLICK_APPLY_FILTER,
  SET_CURRENT_SELECT_BRANDS,
} from 'redux/actions/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
import { useFilteredProviders } from '../Order/hooks/useFilteredProviders';

dayjs.extend(utc);
dayjs.extend(timezone);

const OrdersHistoryHeader = ({ handleDrawerOpen, open }) => {
  const { currentBusiness } = useSelector(({ business }) => business);
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const query = useQuery();
  const startDateQuery = query.get('start');
  const endDateQuery = query.get('end');
  const skipQuery = query.get('skip') || 0;
  const limitQuery = query.get('limit') || 5;
  const { filteredProviders } = useFilteredProviders();

  const request = JSON.parse(localStorage.getItem('requestData'));
  const { businessIdsForHistory, locationIdsForHistory, applyFilter } = useSelector(({ business }) => business);
  const [openFilterSection, setOpenFilterSection] = useState('OPEN');
  const businessList = businessIdsForHistory?.map((item) => item?.value?.businessId) || [];

  const statusesList = Object.values(ORDERS_FILTER_STATUS_HISTORY).map((item) => item.value) || [];
  // const { orderHistoryList } = useSelector(({ orders }) => orders);
  const [requestData, setRequestData] = useState({
    providers: request ? request?.providers : Object.keys(filteredProviders),
    statuses: request ? request?.statuses : statusesList,
    businesses: businessList,
    skip: Number(skipQuery),
    limit: Number(limitQuery),
    isScheduled: 'all',
    start: dayjs().tz(currentBusiness.timezone?.timeZoneId).subtract(1, 'w').startOf('day').valueOf(),
    end: dayjs().tz(currentBusiness.timezone?.timeZoneId).endOf('day').valueOf(),
  });

  const handleClickFilter = useCallback(
    (_, data) => {
      dispatch({
        type: ORDERS_HISTORY,
        payload: {
          data: {
            count: 0,
            orders: [],
          },
        },
      });
      data['locationIds'] = locationIdsForHistory;
      dispatch({ type: CLICK_APPLY_FILTER, payload: true });
      const { start, end } = data;
      const findIndexProcess = requestData.statuses?.findIndex((item) => item.toLowerCase() === 'processing');
      const findIndexReject = requestData.statuses?.findIndex((item) => item.toLowerCase() === 'expired');
      if (findIndexProcess > -1) {
        requestData.statuses[findIndexProcess] = 'in_progress';
      }
      if (findIndexReject > -1) {
        requestData.statuses[findIndexReject] = 'rejected_by_orders_co';
      }
      requestData.limit = Number(limitQuery);
      requestData.skip = 0;
      const body = {
        start: start ? start : dayjs().tz(currentBusiness.timezone?.timeZoneId).subtract(1, 'w').startOf('day').valueOf(),
        end: end ? end :
          dayjs().tz(currentBusiness.timezone?.timeZoneId).endOf('day').valueOf(),
        ...data,
      };
      let searchQuery = '';
      if (start) {
        searchQuery = `&start=${start}&end=${end}`;
      }

      if (startDateQuery) {
        history.replace({
          pathname: pathname,
          search: history.location.search.split('&start')[0],
        });
      }

      if ((start && end) || (startDateQuery && startDateQuery !== 'NaN') || (endDateQuery && endDateQuery !== 'NaN')) {
        history.replace({
          pathname: pathname,
          search: `${history.location.search}${searchQuery}`,
        });
      }
      localStorage.setItem('requestData', JSON.stringify(body));
      history.push(`${history.location.pathname}?skip=${body.skip}&limit=${body.limit}&page=${0}`);
      dispatch(getOrdersHistory(body, 0, true));
    },
    [startDateQuery, endDateQuery, requestData, skipQuery, limitQuery, locationIdsForHistory]
  );

  const clickFilter = () => {
    if (openFilterSection === 'OPEN') {
      if (!applyFilter) {
        setRequestData({
          providers: Object.keys(filteredProviders),
          statuses: statusesList,
          businesses: [],
          skip: 0,
          limit: 5,
          isScheduled: 'all',
          start: dayjs().tz(currentBusiness.timezone?.timeZoneId).subtract(1, 'w').startOf('day').valueOf(),
          end: dayjs().tz(currentBusiness.timezone?.timeZoneId).endOf('day').valueOf(),
        });
      }
      history.push(`${history.location.pathname}?skip=${0}&limit=${5}&page=${0}`);
      setOpenFilterSection('CLOSE');
      dispatch({ type: SET_HEADER_IS_TOUCHED, payload: 'CLOSE' });
    } else {
      dispatch({ type: SET_HEADER_IS_TOUCHED, payload: 'OPEN' });
      setOpenFilterSection('OPEN');
    }
  };

  useEffect(() => {
    dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: [] });
    dispatch({ type: CLICK_APPLY_FILTER, payload: false });
  }, [history.location.pathname]);

  const filterButtonForHistory = () => {
    return (
      <Box
        sx={{
          marginRight: 2,
        }}
      >
        <Button
          id="openFilterSection"
          sx={{
            background: '#3C4350',
            pt: 0.7,
            pb: 0.7,
            fontSize: 16,
            textTransform: 'inherit',
          }}
          variant="contained"
          onClick={clickFilter}
        >
          <Box
            sx={{
              mr: 1.5,
            }}
            component="img"
            src={filterIcon}
            alt="Filter Orders History"
          />
          {openFilterSection} {t('header.filter')}
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', pt: "8px" }}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>{t("Order History")}</Typography>
        {filterButtonForHistory()}
      </Box>
      {openFilterSection !== 'OPEN' && (
        <Box className="orders-history-filter">
          <OrdersHistoryFilter
            openFilterSection={openFilterSection}
            requestData={requestData}
            setRequestData={setRequestData}
            handleClickFilter={handleClickFilter}
            filteredList={filteredProviders}
          />
        </Box>
      )}
    </>
  );
};

export default OrdersHistoryHeader;
