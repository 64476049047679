// src/components/ResumePauseButton/index.js

import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { updateStopResumeOrders } from 'redux/actions/providers';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';

import useCountDown from './useCountDown';

/**
 * ResumePauseButton Component
 *
 * @param {Object} props
 * @param {boolean} props.hasConnectProvider - Indicates if there's a connected provider.
 * @returns {JSX.Element}
 */
function ResumePauseButton({ hasConnectProvider }) {
  const dispatch = useDispatch();

  // Select only the necessary parts of the state with shallowEqual to prevent unnecessary re-renders
  const stopResumeOrders = useSelector(
    (state) => state.providers.stopResumeOrders,
    shallowEqual
  );
  const isBrandLoading = useSelector(
    (state) => state.business.isBrandLoading,
    shallowEqual
  );

  const { statuses, loading } = stopResumeOrders;
  const { status } = statuses;

  const { duration } = useCountDown(statuses, () => {
    return dispatch(
      updateStopResumeOrders({
        ...stopResumeOrders,
        data: {
          ...stopResumeOrders.data,
          action: PAUSE_RESUME_STATUSES.RECEIVING,
        },
        statuses: {
          ...stopResumeOrders.statuses,
          paused_until: '',
          status: PAUSE_RESUME_STATUSES.RECEIVING,
        },
      })
    );
  });

  const iconStyle = useMemo(() => {
    switch (status) {
      case PAUSE_RESUME_STATUSES.RECEIVING:
        return { backgroundColor: '#11AF22' };
      case PAUSE_RESUME_STATUSES.PARTIAL:
      case PAUSE_RESUME_STATUSES.BUSY:
        return { backgroundColor: '#F2AE48' };
      default:
        return { backgroundColor: 'red' };
    }
  }, [status]);

  // Memoize the click handler to maintain stable references
  const handleShowResumePause = useCallback(() => {
    if (!duration) return;
    dispatch(updateStopResumeOrders({ showModal: true }));
  }, [dispatch, duration]);

  // Early return for disabled state
  if (!hasConnectProvider && !loading && !isBrandLoading) {
    return (
      <LoadingButton
        id="resumePauseButton"
        size="small"
        variant="outlined"
        sx={{
          color: 'black',
          textTransform: 'none',
          height: '40px',
          width: '100%',
          maxWidth: '240px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }}
        disabled
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>N/A</Typography>
      </LoadingButton>
    );
  }


  return (
    <LoadingButton
      id="resumePauseButton"
      size="small"
      onClick={handleShowResumePause}
      variant="outlined"
      loading={loading || isBrandLoading}
      startIcon={
        !isBrandLoading &&
        !loading && (
          <Box
            sx={{
              ...iconStyle,
              mr: 1,
              width: 12,
              height: 12,
              borderRadius: '50%', // Ensures circular shape
            }}
          />
        )
      }
      sx={{
        color: 'black',
        textTransform: 'none',
        height: '40px',
        width: '100%',
        minWidth: '240px',
        maxWidth: '240px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      }}
    >
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>{duration}</Typography>
    </LoadingButton>
  );
}

// Wrap with React.memo to prevent unnecessary re-renders
export default React.memo(ResumePauseButton);
