import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ClearCacheProvider } from 'react-clear-cache';

import App from './App';
import Home from 'pages/Home';
import UserSignup from 'pages/Auth/UserSignup';
import AuthorizationStepsWrapper from 'pages/InvitationLocation/Authorization/AuthorizationStepsWrapper';

import store from './store';
import theme from './Theme/index';
import { SocketProvider } from 'providers/Socket';
import { PaymentPeriodicityProvider } from 'context/paymentPeriodicityContext';
import { SubscriptionConfigProvider } from 'context/subscriptionPlanConfigContext';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_s9JBJsgJuCpTyuvM457NhmTU00Axogybz7');

ReactDOM.render(
  // <React.StrictMode>

  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <SocketProvider>
            <PaymentPeriodicityProvider>
              <SubscriptionConfigProvider>
                <Router>
                  <Switch>
                    <Route exact path="/reports/:uuid" component={Home} />
                    <Route exact path="/location/invitation/:id" component={AuthorizationStepsWrapper} />
                    <Route exact path="/complete-signup/:token" component={UserSignup} />
                    <Route path="/" component={App} />
                  </Switch>
                </Router>
              </SubscriptionConfigProvider>
            </PaymentPeriodicityProvider>
            {/* Moved ToastContainer outside Router for global access */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
          </SocketProvider>
        </Provider>
      </Elements>
    </ThemeProvider>
  </StyledEngineProvider>

  //</React.StrictMode>
  ,
  document.getElementById('root')
);

// Optionally register the service worker for offline capabilities
serviceWorker.unregister();
