import React, { memo, useMemo, useState, useEffect } from 'react';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { ORDER_GROUP, ORDER_STATUS } from 'constants/config';
import { getTypeText } from 'services/helpers';
import moment from 'moment-timezone';
import Preloader from 'components/Preloader';
import Currency from 'components/Currency';
import { useSelector } from 'react-redux';
import { Paper, Box, Typography } from '@mui/material';
import StyledDataGrid from 'components/DataGridTable';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import Tooltip from '@mui/material/Tooltip';
import { StyledTitleTableOrdering } from 'components/Order/OrderConfirmModal/styled';

const OrdersList = ({ data, loading, onSelect, isSubOrder = false }) => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();

  const { currentBusiness } = useSelector(({ business }) => business);
  const [pageSize, setPageSize] = useState(5);
  const [rows, setRows] = useState([]);

  const tzId = currentBusiness?.timezone ? currentBusiness.timezone?.timeZoneId : 'America/Los_Angeles';

  useEffect(() => {
    if (!data) {
      return;
    }
    const newRowData = data.map((item) => {
      const isSubOrder = item.orderGroup === ORDER_GROUP.suborder;
      const isGroupOrder = item.orderGroup === ORDER_GROUP.group;
      if (isSubOrder && item.businessInfo) {
        item.business = item.businessInfo;
      }
      const modifiedData = {
        business: item.business[0]?.companyShortName,
        businessTitle: item.business[0]?.title,
        locationId: item.business[0]?.locationId,
        timeZoneId: item.business[0].timezone?.timeZoneId,
        provider: item.provider,
        contactInfo: item.contactInfo.name,
        type: item.type,
        beReadyTime: item.beReadyTime,
        status: item.status.status,
        charges: item.charges.total,
        id: item?.id,
        _id: item._id,
        number: item.number,
        modified: item.modified,
        isScheduled: item.isScheduled,
        providerName: item.providerName,
        firstName: item.contactInfo.firstName,
        lastName: item.contactInfo.lastName,
        orderGroup: item.orderGroup,
        suborders: item.suborders,
      };

      if (isGroupOrder) {
        modifiedData.businessInfo = item.business;
      }
      if (item.provider === 'table_ordering') {
        modifiedData.orderMetaData = item?.orderMetaData;
      }
      return modifiedData;
    });

    setRows(newRowData);
  }, [data]);

  const columns = useMemo(() => [
    {
      field: 'business',
      headerName: t('orders_history.brand'),
      flex: 1,
      sortable: false,
      filterable: true,
      editable: false,
      hide: true,
      disableColumnMenu: false,
      renderCell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
    },
    {
      field: 'businessTitle',
      headerName: t('Business Title'),
      flex: 1,
      sortable: true,
      filterable: true,
      editable: false,
      hide: false,
      disableColumnMenu: false,
      renderCell: ({ value, row }) => {
        return (
          <Tooltip title={value || ''} placement="top">
            <Typography>{value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'locationId',
      headerName: t('Location Id'),
      flex: 1,
      sortable: true,
      filterable: true,
      editable: false,
      hide: true,
      disableColumnMenu: false,
      renderCell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
    },
    {
      field: 'providerName',
      headerName: t('orders_history.provider'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      disableColumnMenu: true,
      renderCell: ({ value, row }) => {
        if (value === 'table_ordering') {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={providers[row.provider]?.logoUrl} width="30" height="30" alt="order" className="rounded" />
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  ml: 1,
                  textOverflow: 'ellipsis',
                  width: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {row?.orderMetaData?.tableNumber}
              </Typography>
            </Box>
          );
        }
        return <img src={providers[row.provider]?.logoUrl} width="40" height="40" alt="order" className="rounded" />;
      },
    },
    {
      field: 'contactInfo',
      headerName: t('orders_history.client'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      valueGetter: (params) => {
        const { row } = params;
        return `${row?.firstName || ''} ${row?.lastName || ''} #${row?.number || ''}`;
      },
      renderCell: ({ value, row }) => {
        console.log(value, 'value');
        if (row?.provider === 'table_ordering' && row?.type === 'take_out') {
          return (
            <Box sx={{ width: '100%' }}>
              <Tooltip title={`${row?.firstName} ${row?.lastName}`} placement={'top'}>
                <Box sx={{ display: 'flex', gap: '5px', maxWidth: '156px' }}>
                  <StyledTitleTableOrdering>
                    {row?.firstName || ''} {row?.lastName || ''}
                  </StyledTitleTableOrdering>
                </Box>
              </Tooltip>
              <Typography>{`#${row.number}`}</Typography>
            </Box>
          );
        } else {
          return (
            <Box sx={{ width: '100%' }}>
              <Tooltip title={`${row?.firstName} ${row?.lastName}`} placement={'top'}>
                <StyledTitleTableOrdering>{row?.firstName || ''} {row?.lastName || ''}</StyledTitleTableOrdering>
              </Tooltip>
              <Typography>{`#${row.number}`}</Typography>
            </Box>
          );
        }
      },
    },
    {
      field: 'type',
      headerName: t('Type'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      renderCell: ({ value }) => {
        return <Typography>{getTypeText(value, t)}</Typography>;
      },
    },
    {
      field: 'beReadyTime',
      headerName: t('orders_history.be_ready_time'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        const orderTzId = row.timeZoneId;
        return row.status.status !== 'unconfirmed' ? (
          <Box
            className={isLateOrder(moment.utc(value).tz(orderTzId || tzId), row.status.status) ? 'text-blink' : ''}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Typography sx={{ fontWeight: 700 }}>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                ?.format('hh:mm A')}
            </Typography>
            <Typography>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('MMM Do')}
            </Typography>
            {row.isScheduled && <Typography>{<i className="icon icon-alarm-check text-info ml-2" />}</Typography>}
          </Box>
        ) : (
          <Box
            className={
              isLateOrder(moment.utc(row.beReadyTime).tz(orderTzId || tzId), row.status.status) ? 'text-blink' : ''
            }
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Typography>TBD</Typography>
            <Typography sx={{ fontWeight: 700 }}>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('hh:mm A')}
            </Typography>
            <Typography>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('MMM Do')}
            </Typography>
            {row.isScheduled && <Typography>{<i className="icon icon-alarm-check text-info ml-2" />}</Typography>}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: t('orders_history.status'),
      editable: false,
      flex: 1,
      sortable: false,
      minWidth: 125,
      filterable: true,
      renderCell: ({ value, row }) => {
        return <Status value={value} modified={row.modified} />;
      },
    },
    {
      field: 'charges',
      headerName: t('order_view.total'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      float: 'right',
      renderCell: ({ value, row }) => {
        return <Currency value={value} />;
      },
    },
  ]);

  const isLateOrder = (date, status) => {
    return status === 'in_progress'
      ? Number(new Date(date)) < Date.now() + 60000
      : status === 'ready_for_pickup'
        ? Number(new Date(date)) < Date.now() - 60000 * 15
        : false;
  };

  // MuiTableRow-hover
  return (
    <div className="order-list">
      {loading ? (
        <Preloader overlay />
      ) : data.length > 0 ? (
        <Paper>
          <Box
            sx={{
              height: isSubOrder ? 'calc(100vh - 300px)' : 'calc(100vh - 136px)',
              width: '100%',
              // my: 2,
            }}
          >
            <StyledDataGrid
              hideFooterPagination={false}
              hideFooterSelectedRowCount={false}
              isEditable={false}
              disableSelectionOnClick={true}
              disableVirtualization={true}
              rows={rows}
              columns={columns}
              rowHeight={110}
              pageSize={pageSize}
              onRowClick={({ row }) => {
                return onSelect(row);
              }}
              getRowId={(row) => row._id}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 15, 50, 100]}
            />
          </Box>
        </Paper>
      ) : !loading && !isSubOrder ? (
        <Box sx={{ mt: '100px' }}>
          <EmptyMenu text={t('orders_history.there_are_orders_yet')} />
        </Box>
      ) : (
        <></>
      )}
    </div>
  );
};

const Status = ({ value, modified }) => {
  const { t } = useTranslation();
  const status = ORDER_STATUS[value];
  return (
    <>
      <Box display="flex" alignItems="center">
        {!status ? (
          <p>{value}</p>
        ) : (
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              {status.icon ? (
                <i className={`icon  ${status.icon}`} style={{ color: status.color }}></i>
              ) : (
                <div className="status-icon" style={{ backgroundColor: status.color }} />
              )}
              <Typography>{t(status.name)}</Typography>
            </Box>
            <Typography align="center" color="error">
              {modified && 'Modified'}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(OrdersList);
