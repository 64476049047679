const durations = [
  {
    text: '5_mins_',
    value: 300,
    unit: 'seconds',
    description: 'your_store_receive_for_5_mins',
    // description: '- Your store won’t receive new orders for 5 mins.',
  },
  {
    text: '10_mins_',
    value: 600,
    unit: 'seconds',
    description: 'your_store_receive_for_10_mins',
  },
  {
    text: '15_mins_',
    value: 900,
    unit: 'seconds',
    description: 'your_store_receive_for_15_mins',
  },
  {
    text: '20_mins_',
    value: 1200,
    unit: 'seconds',
    description: 'your_store_receive_for_20_mins',
  },

  {
    text: '30_mins_',
    value: 1800,
    unit: 'seconds',
    description: 'your_store_receive_for_30_mins',
  },
  {
    text: '45_mins_',
    value: 2700,
    unit: 'seconds',
    description: 'your_store_receive_for_45_mins',
  },
  {
    text: '1_hour_',
    value: 3600,
    unit: 'seconds',
    description: 'your_store_receive_for_1_hours',
  },
  {
    text: '2_hour_',
    value: 7200,
    unit: 'seconds',
    description: 'your_store_receive_for_2_hours',
  },
];

const durationsBusy = [
  {
    text: '5_mins_',
    value: 300,
    unit: 'seconds',
    description: 'your_store_receive_for_5_mins',
  },
  {
    text: '10_mins_',
    value: 600,
    unit: 'seconds',
    description: 'your_store_receive_for_10_mins',
  },
  {
    text: '15_mins_',
    value: 900,
    unit: 'seconds',
    description: 'your_store_receive_for_15_mins',
  },
  {
    text: '20_mins_',
    value: 1200,
    unit: 'seconds',
    description: 'your_store_receive_for_20_mins',
  },
  {
    text: '25_mins_',
    value: 1500,
    unit: 'seconds',
    description: 'your_store_receive_for_25_mins',
  },
  {
    text: '30_mins_',
    value: 1800,
    unit: 'seconds',
    description: 'your_store_receive_for_30_mins',
  },
  {
    text: '35_mins_',
    value: 2100,
    unit: 'seconds',
    description: 'your_store_receive_for_35_mins',
  },
  {
    text: '40_mins_',
    value: 2400,
    unit: 'seconds',
    description: 'your_store_receive_for_40_mins',
  },
  {
    text: '45_mins_',
    value: 2700,
    unit: 'seconds',
    description: 'your_store_receive_for_45_mins',
  },
];

const startStopInitValues = {
  duration: [
    ...durations,
    {
      text: 'rest_of_today_',
      description: 'your_store_receive_new_today',
      // description: '- Your store won’t receive new orders for the rest of today.',
      value: 'today',
      unit: 'text',
    },
    {
      text: 'until_make_available_',
      value: 'permanent',
      unit: 'text',
      description: 'your_store_available_again',
      // description: '- Your store won’t receive new orders until you make it available again',
    },
  ],
  durationsBusy: durationsBusy,
  reason: [
    {
      text: 'Restaurant is busy',
      value: 'Restaurant is busy',
    },
    {
      text: 'Out of inventory',
      value: 'Out of inventory',
    },
    {
      text: 'Restaurant problem',
      value: 'Restaurant problem',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ],
  action: [
    {
      text: 'normal_',
      value: 'RECEIVING',
      description: 'business_as_usual',
    },
    {
      text: 'busy_',
      value: 'BUSY',
      description: 'need_more_prep_time_',
    },
    {
      text: 'paused_',
      value: 'PAUSED',
      description: 'pause_new_orders_',
    },
  ],
};

export const PAUSE_RESUME_STATUSES = {
  PAUSED: 'PAUSED',
  RECEIVING: 'RECEIVING',
  PARTIAL: 'PARTIAL',
  LIMITED: 'LIMITED',
  THROTTLING: 'THROTTLING',
  BUSY: 'BUSY',
  PERMANENTLY_PAUSED: 'PERMANENTLY_PAUSED',
};

export const PAUSE_RESUME_STATUSES_LABEL = {
  PAUSED: 'PAUSED',
  RECEIVING: 'ACCEPTING',
  PARTIAL: 'PARTIAL',
  LIMITED: 'LIMITED',
  THROTTLING: 'THROTTLING',
  BUSY: 'BUSY',
  PERMANENTLY_PAUSED: 'PERMANENTLY_PAUSED',
};

export default startStopInitValues;
export const PauseResumeStatusTitle = {
  BUSY: 'orders.busy',
  PAUSED: 'orders.paused',
  RECEIVING: 'orders.normal',
};
export const PauseResumeStatusDescription = {
  BUSY: {
    description: 'pause_resume.add_default_prep_time',
    subDescription: 'pause_resume.pause_resume_description_busy_sub',
  },
  PAUSED: {
    description: 'pause_resume.pause_resume_description_paused',
    subDescription: 'pause_resume.pause_resume_description_paused_sub',
  },
  RECEIVING: {
    description: 'pause_resume.pause_resume_description_normal',
    subDescription: 'pause_resume.send_admins_managers',
  },
};
