import React from 'react';
import { get } from 'lodash';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import { onWheelPreventChangeNumberField } from 'utils/menu';
import { formatNumber } from 'helpers/formPrice';
import { t } from 'i18next';
const CustomTextInput = ({
  name,
  id,
  sx,
  children,
  label = '',
  validate,
  inputProps,
  hidden,
  onChange,
  disabled,
  multiline,
  rows,
  select,
  helperText,
  InputProps,

  onKeyPress,
  onBlur,
  variant = 'outlined',
  ...params
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        if (
          field?.name?.includes('phone') ||
          field?.name?.includes('legalPhone') ||
          field?.name?.includes('brandPhone') ||
          field?.name?.includes('supportPhone')
        ) {
          return (
            <MuiPhoneNumber
              defaultCountry={'us'}
              variant="outlined"
              {...field}
              size={'small'}
              hidden={hidden}
              error={!!parsedError || params.error || false}
              helperText={
                typeof helperText !== 'object'
                  ? t(parsedError ? parsedError.message : params.helperText || '')
                  : helperText
              }
              label={label}
              onWheel={onWheelPreventChangeNumberField}
              disabled={disabled}
              InputProps={InputProps}
              sx={{ ...sx, '& svg': { height: '20px' } }}
              InputLabelProps={{ shrink: true }} />
          );
        } else {
          const formattedValue = field.name.includes('price') ? formatNumber(field.value) : field.value;

          return (
            <TextField
              {...field}
              size="small"
              variant={variant}
              hidden={hidden}
              error={!!parsedError || params.error || false}
              helperText={
                !disabled && typeof helperText !== 'object'
                  ? t(parsedError ? parsedError.message : params.helperText || helperText)
                  : helperText
              }
              label={label}
              inputRef={field.ref}
              disabled={disabled}
              InputProps={InputProps}
              onWheel={onWheelPreventChangeNumberField}
              sx={sx}
              multiline={multiline}
              rows={rows}
              select={select}
              inputProps={inputProps}
              InputLabelProps={{ shrink: true }}
              onChange={onChange || field.onChange}
              onBlur={onBlur || field.onBlur}
              onKeyPress={onKeyPress}
              value={formattedValue === null ? '' : formattedValue}
            >
              {children}
            </TextField>
          );
        }
      }}
      {...params}
    />
  );
};

export default CustomTextInput;
