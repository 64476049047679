import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormControl, Button, Box, Tooltip } from '@mui/material';
import ProviderSelect from './OrderHistoryFilter/ProviderSelect';
import StatusesSelect from './OrderHistoryFilter/StatusesSelect';
import dateIssue from 'assets/icons/dateIssue.svg';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CustomBrandAutoComplete } from '../Forms/CustomBrandAutoComplete';
import LocationAutoComplete from '../Forms/LocationAutoComplete';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import { useLocation } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const createBrandWorker = createWorkerFactory(() => import('components/Forms/convertBrand'));
dayjs.extend(utc);
dayjs.extend(timezone);
const OrdersHistoryFilter = ({ requestData, setRequestData, handleClickFilter, openFilterSection, filteredList }) => {
  const { t } = useTranslation();
  const worker = useWorker(createBrandWorker);
  const [convertedBrand, setConvertedBrand] = React.useState([]);
  const [convertedLocation, setConvertedLocation] = React.useState([]);
  const {
    filterBrandList,
    allLocations,
    currentBusiness,
    locationIdsForHistory,
    brandIdsForHistory,
    currentSelectedBrands,
    applyFilter,
    currentSelectedLocations,
  } = useSelector(({ business }) => business);
  const request = JSON.parse(localStorage.getItem('requestData'));
  const location = useLocation();
  const [dateRange, setDateRange] = useState({
    start_date: request ? request?.start :
      dayjs().tz(currentBusiness.timezone?.timeZoneId).subtract(1, 'w').startOf('day'),
    end_date: request ? request?.end :
      dayjs().tz(currentBusiness.timezone?.timeZoneId).endOf('day'),
  });

  const checkPermission = usePermission();
  const [startDateError, handleStartDateError] = useState(false);
  const [endDateError, handleEndDateError] = useState(false);
  const checkData = requestData.providers.length === 0 || requestData.statuses.length === 0;
  const [show, setShow] = useState(true);

  useEffect(() => {
    (async () => {
      if (checkPermission(all['SUPER'])) {
        const webWorkerBrand = await worker.convertBrandById(filterBrandList);
        const webWorkerLocation = await worker.convertLocationById(allLocations);
        setConvertedBrand(webWorkerBrand);
        setConvertedLocation(webWorkerLocation);
      } else {
        const webWorkerBrand = await worker.convertBrand(filterBrandList);
        setConvertedBrand(webWorkerBrand);
      }
    })();
  }, [filterBrandList, worker, allLocations]);

  useEffect(() => {
    if (!dateRange.start_date) {
      handleStartDateError(t('field_is_required_'));
    } else if (dateRange.end_date) {
      const startDate = new Date(dateRange.start_date);
      const endDate = new Date(dateRange.end_date);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      if (startDate > endDate) {
        handleStartDateError('The start date cannot be after the end date');
      } else {
        handleStartDateError(false);
      }
    } else {
      handleStartDateError(false);
    }

    if (!dateRange.end_date) {
      handleEndDateError(t('field_is_required_'));
    } else if (dateRange.start_date) {
      const startDate = new Date(dateRange.start_date);
      const endDate = new Date(dateRange.end_date);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      if (endDate < startDate) {
        handleEndDateError('The end date cannot be before the start date');
      } else {
        handleEndDateError(false);
      }
    } else {
      handleEndDateError(false);
    }
  }, [dateRange]);

  const onChangeDatePickerStart = (value) => {
    setRequestData({
      ...requestData,
      start: dayjs.tz(value, currentBusiness.timezone?.timeZoneId).valueOf(),
    });
    setDateRange({
      ...dateRange,
      start_date: value,
    });
  };

  const onChangeDatePickerEnd = (value) => {
    setRequestData({
      ...requestData,
      end: dayjs.tz(value, currentBusiness.timezone?.timeZoneId).valueOf(),
    });
    setDateRange({
      ...dateRange,
      end_date: value,
    });
  };

  const resizeHandler = () => {
    if (window.innerWidth < 1700) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [openFilterSection]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [show]);

  const getLocationValue = useMemo(() => {
    if (location.pathname === '/history' && checkPermission(all['SUPER'])) {
      return convertedLocation;
    } else {
      return allLocations;
    }
  }, [location, all, convertedLocation, allLocations]);

  return (
    <Box sx={{ px: 1 }}>
      <Box
        className="orders-history-main"
        sx={{ display: 'flex', alignItems: 'end', gap: '10px', flexWrap: 'wrap', pb: 2 }}
      >
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <Box sx={{ maxWidth: '180px' }} id="start-date-wrapper" className="pl-0">
                {startDateError && (
                  <Tooltip title={startDateError} placement="top" arrow>
                    <img
                      width={16}
                      height={16}
                      alt="Date Issue Icon"
                      src={dateIssue}
                      style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                    />
                  </Tooltip>
                )}
                <DatePicker
                  name="start_date"
                  value={dayjs.tz(dateRange.start_date, currentBusiness.timezone?.timeZoneId)}
                  onChange={onChangeDatePickerStart}
                  sx={{
                    input: { padding: `8px 0 8px ${startDateError ? '35px' : '12px'}` },
                    fieldset: { borderColor: startDateError ? '#E21508 !important' : '' },
                  }}
                  label={
                    <span style={{ backgroundColor: '#FFF' }} className={`${startDateError ? 'text-danger' : ''}`}>
                      {t('website_settings.start_date')}
                    </span>
                  }
                />
              </Box>
              <Box sx={{ maxWidth: '180px', position: 'relative' }} id="end-date-wrapper" className="pl-0">
                {endDateError && (
                  <Tooltip title={endDateError} placement="top" arrow>
                    <img
                      width={16}
                      height={16}
                      alt="Date Issue Icon"
                      src={dateIssue}
                      style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                    />
                  </Tooltip>
                )}
                <DatePicker
                  name="end_date"
                  value={dayjs.tz(dateRange.end_date, currentBusiness.timezone?.timeZoneId)}
                  onChange={onChangeDatePickerEnd}
                  sx={{
                    input: { padding: `8px 0 8px ${endDateError ? '35px' : '12px'}` },
                    fieldset: { borderColor: endDateError ? '#E21508 !important' : '' },
                  }}
                  label={
                    <span style={{ backgroundColor: '#FFF' }} className={`${endDateError ? 'text-danger' : ''}`}>
                      {t('website_settings.end_date')}
                    </span>
                  }
                />
              </Box>
            </Box>
          </LocalizationProvider>
        </FormControl>
        <ProviderSelect show={show} requestData={requestData} setRequestData={setRequestData} filteredList={filteredList} />
        <StatusesSelect show={show} requestData={requestData} setRequestData={setRequestData} />
        <Box>
          <CustomBrandAutoComplete
            setRequestData={setRequestData}
            requestData={requestData}
            convertedBrand={convertedBrand}
            currentSelectedBrands={currentSelectedBrands}
            applyFilter={applyFilter}
            isHistory={true}
          />
        </Box>
        <Box>
          <LocationAutoComplete
            locationsList={getLocationValue}
            setRequestData={setRequestData}
            requestData={requestData}
            isHistory={true}
            currentSelectedLocations={currentSelectedLocations}
            applyFilter={applyFilter}
          />
        </Box>
        <Button
          id="ordersHistoryApply"
          color="primary"
          sx={{
            padding: show ? '7px 53px' : 0,
          }}
          disabled={
            Boolean(startDateError) ||
            Boolean(endDateError) ||
            checkData ||
            Boolean(locationIdsForHistory.length === 0) ||
            Boolean(brandIdsForHistory.length === 0)
          }
          onClick={(_) => handleClickFilter(_, requestData)}
        >
          {t('orders_history.apply')}
        </Button>
      </Box>
    </Box>
  );
};
export default memo(OrdersHistoryFilter);
