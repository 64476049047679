import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinkedProviderPopover from '../LinkedProviderPopover';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Private from 'components/Private';
import InfoBulkIcon from 'assets/icons/info_icon_bulk.svg';
import moment from 'moment-timezone';
import { renderPermanently } from 'utils/soldOut';
import { addDefaultPriceOverride } from 'utils/menu';
import { useDispatch } from 'react-redux';
import { addModifier, editModifierV2 } from 'redux/actions/Menu/modifiers';
import { CONTEXT_TYPES } from 'constants/menu';
import { confirm } from '../ConfirmModal';
import { useTranslation } from 'react-i18next';
import { NAME_ENUMS } from 'constants/products';
import { set } from 'lodash';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
`;
const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: (props) => `${props.width}px`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    ol: {
      flexWrap: 'nowrap',
      width: '100%',
    },
  },
}));

const FormHeader = ({
  title,
  currentProduct,
  cid,
  entityType,
  currentModifierName,
  setCurrentModifierName,
  modifierFormState,
  // currentModifier,
  mode,
  handleDelete,
  category,
  menu,
  connectedProviders,
  data,
  formProduct,
  isDirty,
  setIsDirty,
  productId,
  currentModifier,
  setTabValue,
  modifierMethods,
  formErrors,
  setLoading,
  setLoadingDuplicate,
  setIsChanged,
  handleErrors,
  updateFormErrors,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const productTitleRef = useRef();
  const headRef = useRef();
  const trimTrim = title?.trim();
  const categoryId = menu.categories?.find((el) => el._id === cid);
  const isEdit = mode === 'edit';
  const isInModifierGroupPage = history.location.pathname.includes('/menu/modifierGroups');
  const classes = useStyle();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateModifiersMinMax = (formStateData) => {
    const isZero = (value) => value !== undefined && value !== '' && value !== null && Number(value) === 0;
    let hasError = false;
    let hasErrorMax = false;

    const checkEmptyOnlyZero = (fieldValue) => {
      if (isZero(fieldValue)) {
        hasError = true;
      }
    };
    const checkEmptyOnlyZeroMax = (fieldValue) => {
      if (isZero(fieldValue)) {
        hasError = true;
      }
    };
    checkEmptyOnlyZero(formStateData?.min);
    checkEmptyOnlyZeroMax(formStateData?.max);

    if (hasError) return false;
    if (hasErrorMax) return false;

    return true;
  };

  const validateModifiers = (formStateData) => {
    const isEmptyWithZero = (value) => value === undefined || value === null || value === '' || Number(value) === 0;
    const isEmpty = (value) => value === undefined || value === null || value === '';
    const isZero = (value) => value !== undefined && value !== '' && value !== null && Number(value) === 0;

    let hasError = false;
    let hasEmptyError = false;
    let hasLastError = false;
    let hasMostError = false;

    // Check for empty values where zero is considered invalid
    const checkEmptyWithZero = (fieldValue) => {
      if (isEmptyWithZero(fieldValue)) {
        hasError = true;
      }
    };
    const checkEmptyOnlyZero = (fieldValue) => {
      if (isZero(fieldValue)) {
        hasLastError = true;
      }
    };

    const checkEmptyOnlyZeroMax = (fieldValue) => {
      if (isZero(fieldValue)) {
        hasMostError = true;
      }
    };

    // Check for empty values where zero is not considered invalid
    const checkEmpty = (fieldValue) => {
      if (isEmpty(fieldValue)) {
        hasEmptyError = true;
      }
    };

    // Validate fields
    checkEmpty(formStateData?.minTotalNumberOfModifierItems);
    checkEmptyWithZero(formStateData?.maxTotalNumberOfModifierItems);

    checkEmptyOnlyZero(formStateData?.min);
    checkEmptyOnlyZeroMax(formStateData?.max);

    checkEmptyWithZero(formStateData?.minAnySingleModifierItems);
    checkEmptyWithZero(formStateData?.maxAnySingleModifierItems);

    return !(hasError || hasLastError || hasMostError || hasEmptyError);
  };

  const goToProductView = async (e) => {
    if (entityType === 'modifier') {
      if (isDirty) {
        const confirmed = await confirm({
          hideCloseIcon: false,
          hideDiscardBtn: false,
        });
        e.preventDefault();
        if (confirmed && confirmed !== 'discard') {
          if (modifierFormState?.canSelectMultipleOptions) {
            const isValidOption = validateModifiers(modifierFormState);
            if (!isValidOption) return;
          } else {
            const isValidOptionMInMax = validateModifiersMinMax(modifierFormState);
            if (!isValidOptionMInMax) return;
          }
        }
        const priceError = formErrors?.list?.filter((item) => item?.price?.error) || [];
        if (handleErrors(modifierFormState) || priceError.length || formErrors?.['title']?.error) {
          return;
        }
        modifierFormState.list.forEach((item, index) => {
          item.optionSort = index;
        });
        setLoading(true);
        if (confirmed && confirmed !== 'discard') {
          if (mode !== 'edit') {
            dispatch(addModifier({ ...modifierFormState }))
              .then((res) => {
                if (!isInModifierGroupPage) {
                  goToModifierOptions(res);
                } else {
                  history.push({
                    search: `entityType=modifier&mid=${res._id}&mode=edit`,
                    state: {
                      goBack: true,
                    },
                  });
                  setIsDirty(false);
                  if (setIsChanged) {
                    setIsChanged(false);
                  }
                  setTabValue(1);
                }
              })
              .finally(() => {
                // reset(values);
                setLoading(false);
              });
          } else {
            addDefaultPriceOverride(category, menu, CONTEXT_TYPES, modifierFormState);
            dispatch(editModifierV2({ ...modifierFormState }))
              .then((res) => {
                goToModifierOptions(res);
                setIsDirty(false);
                if (setIsChanged) {
                  setIsChanged(false);
                }
                setTabValue(1);
              })
              .finally(() => {
                // reset(values);
                setLoading(false);
              });
          }

          history.push({
            search: `?entityType=item&pid=${currentProduct?._id}&cid=${cid}&mode=edit`,
            state: {
              goBack: true,
            },
          });
          setIsDirty(false);
          setTabValue(1);
        } else {
          setLoading(false);
          setCurrentModifierName('');
          history.push({
            search: `?entityType=item&pid=${currentProduct?._id}&cid=${cid}&mode=edit`,
            state: {
              goBack: true,
            },
          });
          setIsDirty(false);
          updateFormErrors({});
        }
      } else {
        setLoading(false);
        setCurrentModifierName('');
        updateFormErrors({});
        setIsDirty(false);
        history.push({
          search: `?entityType=item&pid=${currentProduct?._id}&cid=${cid}&mode=edit`,
        });
      }
    } else {
      setLoading(false);
      history.push({
        search: `?entityType=item&pid=${currentProduct?._id}&cid=${cid}&mode=edit`,
      });
    }
  };

  const goToModifierOptions = (res = null, duplicateIds = null) => {
    const catId = duplicateIds?.catId || '';
    const pId = duplicateIds?.pId || '';
    const isError = Object.values(formErrors || {})?.find((err) => err.error)?.error;
    if (isError) {
      return;
    }

    if (!isInModifierGroupPage) {
      const exist = modifierMethods.fields.findIndex((item) => item?._id === res?._id);
      if (exist !== -1) {
        modifierMethods.update(exist, res);
      } else {
        modifierMethods.append(res);
      }

      setTabValue(null, 1);
      if (catId && pId) {
        history.push({
          search: `?entityType=item&pid=${pId}&cid=${catId}&mode=edit`,
          state: { create: true, tabValue: 1 },
        });
      } else {
        history.push({
          search: `?entityType=item&pid=${productId}&cid=${categoryId}&mode=edit`,
        });
      }
    } else {
      history.push({
        search: `entityType=modifier&mid=${res?._id || currentModifier?._id}&mode=edit`,
      });
    }
  };

  const isInOverview = history?.location.pathname?.includes('/overview');

  const goBack = () => {
    if (history.location.pathname.includes('popularItems')) {
      history.push('/menu/popularItems');
    } else history.push('/menu/items');
  };

  const onDelete = () => {
    setAnchorEl(null);
    handleDelete();
  };

  const onDuplicate = () => {
    handleClose();

    setLoadingDuplicate(true);
    const newModifier = {
      ...currentModifier,
      name: `Copy-${currentModifier?.name}`,
    };
    delete newModifier.id;
    delete newModifier._id;
    dispatch(addModifier({ ...newModifier }))
      .then((res) => {
        if (setTabValue) {
          setTabValue(null, 1);
        }
        if (!isInModifierGroupPage) {
          goToModifierOptions(res, { catId: currentProduct?.category, pId: currentProduct?._id });
        } else {
          if (isInOverview) {
            history.push({
              search: `entityType=modifier&pid=${currentProduct?._id}&cid=${currentProduct?.category}mid=${res._id}&mode=edit`,
            });
          } else {
            history.push({
              search: ``,
            });
          }
        }
      })
      .finally(() => {
        setLoadingDuplicate(false);
      });
  };
  const onItemChanges = () => { };

  const showThreeDotsAndMenu = () => {
    return (
      <>
        <IconButton id="formHeaderMore" sx={{ ml: 1 }} disableRipple onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {entityType !== 'modifier' ? (
            [
              <MenuItem key="delete" disabled={!isEdit} onClick={onDelete}>
                {t('settings.delete')}
              </MenuItem>,
              <MenuItem key="duplicate" disabled onClick={onDuplicate}>
                {t('menu.duplicate')}
              </MenuItem>,
              <MenuItem key="see_changes" disabled onClick={onItemChanges}>
                {t('menu.see_item_changes')}
              </MenuItem>,
            ]
          ) : (
            <>
              <MenuItem onClick={onDuplicate}>{t('menu.duplicate')}</MenuItem>
            </>
          )}
        </Menu>
      </>
    );
  };

  return (
    <Box
      sx={{
        px: 4,
        py: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
      }}
      className="header-menu"
    >
      {entityType === 'modifier' ? (
        <StyledBreadcrumbs
          sx={{
            width: '100%',
            fontSize: '1.25rem',
            fontWeight: 600,
            color: 'text.primary',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          ref={headRef}
          className={classes.root.ol}
          aria-label="breadcrumb"
        >
          <IconButton id="formHeaderBack" onClick={goToProductView}>
            <ArrowBackIcon />
          </IconButton>
          <Tooltip title={currentProduct?.name || ''} placement="top">
            <Typography
              ref={productTitleRef}
              className={classes.root}
              sx={{
                color: '#9E9EAD',
                fontSize: '1.25rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              color="inherit"
            >
              {currentProduct?.name}
            </Typography>
          </Tooltip>

          {currentModifierName ? (
            <Tooltip title={currentModifierName || ''} placement="top">
              <Typography
                className={classes.root}
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                color="text.primary"
              >
                {currentModifierName}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              color="text.primary">
              {t('menu.add_new_modifier')}
            </Typography>
          )}
        </StyledBreadcrumbs>
      ) : isInOverview ? (
        <>
          <StyledBreadcrumbs
            // aria-label="breadcrumb"
            sx={{
              width: '100%',
              fontSize: '1.25rem',
              fontWeight: 600,
              color: 'text.primary',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <IconButton
              id="formHeaderArrowBack"
              onClick={() => {
                updateFormErrors({});
                history.goBack();
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title={category?.name || ''} placement="top">
              <Typography
                className={classes.root}
                sx={{
                  color: '#9E9EAD',
                  fontSize: '1.25rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                color="inherit"
              >
                {category?.name}
              </Typography>
            </Tooltip>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!isInOverview && <KeyboardBackspaceIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={goBack} />}
              <Tooltip title={trimTrim || ''} placement="top">
                <Typography className={classes.root}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="h6"
                >
                  {trimTrim || t('menu.add_new_item')}
                </Typography>
              </Tooltip>
              {isEdit && (
                <LinkedProviderPopover
                  id={currentProduct?._id}
                  connectedProviders={connectedProviders}
                  target="item"
                  targetConnectedProviders={category?.connectedProviders}
                  targetData={data}
                />
              )}
            </Box>
          </StyledBreadcrumbs>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 1.5,
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                color: '#EF3061',
                fontWeight: 500,
                justifyContent: 'center',
                display: 'flex',
              }}
              variant="body1"
            >
              {renderPermanently(formProduct || data, moment) && isEdit ? (
                <>
                  <Box component="img" sx={{ mr: 0.5 }} src={InfoBulkIcon} alt="INFO_BULK_ICON" />
                  {t(renderPermanently(formProduct || data, moment))}
                </>
              ) : (
                ''
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!isInOverview && <KeyboardBackspaceIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={goBack} />}
            <Tooltip title={trimTrim || ''} placement="top">
              <Typography sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
                variant="h6"
              >
                {trimTrim || t('menu.add_new_item')}
              </Typography>
            </Tooltip>
            <Private permission="MENU_EDIT">
              {isEdit && !currentProduct?.modifierId && (
                <LinkedProviderPopover
                  id={currentProduct?._id}
                  categoryId={categoryId?._id}
                  connectedProviders={connectedProviders}
                  target="item"
                  targetConnectedProviders={categoryId ? categoryId?.connectedProviders : menu.connectedProviders}
                  targetData={data}
                />
              )}
            </Private>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 0.5,
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                color: '#EF3061',
                fontWeight: 500,
                justifyContent: 'center',
                display: 'flex',
              }}
              variant="body1"
            >
              {renderPermanently(formProduct || data, moment) && isEdit ? (
                <>
                  <Box component="img" sx={{ mr: 0.5 }} src={InfoBulkIcon} alt="INFO_BULK_ICON" />
                  {t(renderPermanently(formProduct || data, moment))}
                </>
              ) : (
                ''
              )}
            </Typography>
          </Box>
        </Box>
      )}
      <Private permission="MENU_EDIT">{showThreeDotsAndMenu()}</Private>
    </Box>
  );
};

export default FormHeader;
