import React, { memo, lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { arrayMove } from 'react-sortable-hoc';
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, Divider, Stack, Button, TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { set } from 'lodash';
import { CONTEXT_TYPES } from 'constants/menu';
import Preloader from 'components/Preloader';
import { priceTransform } from 'helpers/formPrice';
import { onWheelPreventChangeNumberField } from 'utils/menu';
import { useTranslation } from 'react-i18next';
import LocalizationPopover from 'components/LocalizationPopover';
import useProviderConfig from 'hooks/useProviderConfig';
import { validationModifierCustomersCan } from 'utils/globalUtils';
import { NAME_ENUMS } from 'constants/products';
import MinAndMaxOptions from 'components/MenuNew/FormView/ModifierFormGroup/MinAndMaxOptions';
import { useLocalizationPopover } from 'components/LocalizationPopover/hooks/useLocalizationPopover';
import useMinAndMaxOptions from 'components/MenuNew/FormView/ModifierFormGroup/hooks/useMinAndMaxOptions';
import useQuery from 'hooks/useQuery';

// import SortableContainerList from './SortableContainerList';
const SortableContainerList = lazy(() => import('./SortableContainerList'));

const ModifierGroupForm = ({
  mode,
  // changeIsOnePrice,
  currentProduct,
  isInOverview,
  isInModifierGroupPage,
  formState,
  formErrors,
  updateFormState,
  updateFormErrors,
  handleErrors,
  setIsDirty,
  setIsChanged,
  setChanged,
}) => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const {
    open,
    handleClose,
    handleClickPopover,
    languageLimit,
    currentSelectedField,
    currentSelectedLabel,
    currentSelectedValues,
    inputRef,
    textAreaRef,
    internalRef,
  } = useLocalizationPopover();
  const {
    isOnePrice,
    products,
    list,
    name,
    title,
    description,
    min,
    max,
    free,
    _id,
    minTotalNumberOfModifierItems,
    maxTotalNumberOfModifierItems,
    minAnySingleModifierItems,
    maxAnySingleModifierItems,
    canSelectMultipleOptions,
  } = formState;
  const isEdit = mode === 'edit';
  const history = useHistory();
  const currentProductIsUber = currentProduct?.connectedProviders?.[providers?.ubereats?.srv] || false;
  const { menu, loading } = useSelector(({ menu }) => menu);
  const sizeTypeModifiers = currentProduct?.sizeTypeModifiers;
  let connectedProviders = menu.connectedProviders;
  if (!isInModifierGroupPage) {
    connectedProviders = currentProduct?.connectedProviders;
  }
  const {
    handleChangeMultipleOptions,
    handleChangeFieldMaxTotalNumberOfModifierItems,
    handleChangeFieldMinTotalNumberOfModifierItems,
  } = useMinAndMaxOptions(
    setIsDirty,
    setIsChanged,
    formState,
    updateFormState,
    formErrors,
    updateFormErrors,
    list,
    max
  );
  const checkUber = products?.some((item) => item.connectedProviders?.[providers.ubereats.srv]) || currentProductIsUber;
  const labelProps = {
    shrink: true,
  };
  const query = useQuery();
  const entityType = query.get('entityType');

  useEffect(() => {
    if (mode === 'create' && entityType === 'modifier') {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [mode, inputRef]);

  useEffect(() => {
    if (checkUber && list?.length) {
      const indexOf = list.findIndex((item) => item.price > 374);
      if (indexOf !== -1) {
        updateFormErrors({
          ...set(formErrors, `list[${indexOf}].price`, {
            error: true,
            message: t('price_$375.00_for_ubereats_'),
          }),
        });
      }
    }
  }, [checkUber, list, formState]);

  useEffect(() => {
    const newPriceOverride = [];
    const formStateCopy = { ...formState, list: [...formState.list] };
    formStateCopy.list.forEach((listItem, index) => {
      const priceOverride = [...listItem.priceOverride];
      sizeTypeModifiers &&
        sizeTypeModifiers[0] &&
        sizeTypeModifiers[0].list.forEach((currentItem, itemIndex) => {
          const found = listItem.priceOverride.find((item) => {
            return item.context_value === currentItem._id;
          });
          if (!found) {
            priceOverride.push({
              context_type: 'MOD_ITEM',
              context_value: currentItem?._id,
              name: currentItem.name,
              price: 0,
              priceOverride: newPriceOverride,
            });
          }
        });
      listItem.priceOverride = priceOverride;
    });
    updateFormState(formStateCopy);
  }, [isOnePrice, currentProduct?.isOnePrice]);

  const handleAddItem = () => {
    if (handleErrors(formState, true)) {
      return;
    }
    if (isOnePrice) {
      const withNewList = {
        ...formState,
        list: [
          {
            name: '',
            price: 0,
            priceOverride: [{ context_type: CONTEXT_TYPES.provider, context_value: 'main', price: 0 }],
            optionSort: 0,
            connectedProviders: connectedProviders,
          },
          ...list,
        ],
      };
      updateFormState(withNewList);
    } else {
      const newPriceOverride = [];
      const priceOverride = [];
      if (connectedProviders) {
        Object.keys(connectedProviders).map((modItem) => {
          if (connectedProviders[modItem]) {
            newPriceOverride.push({
              context_type: CONTEXT_TYPES.provider,
              context_value: modItem,
              name: modItem,
              price: 0,
            });
          }
        });
      }
      sizeTypeModifiers &&
        sizeTypeModifiers[0] &&
        sizeTypeModifiers[0].list.forEach((currentItem) => {
          priceOverride.push({
            context_type: 'MOD_ITEM',
            context_value: currentItem?._id,
            name: currentItem.name,
            price: 0,
            priceOverride: newPriceOverride,
          });
        });
      const withNewList = {
        ...formState,
        list: [
          {
            name: '',
            price: 0,
            priceOverride,
            optionSort: 0,
            connectedProviders: connectedProviders,
          },
          ...list,
        ],
      };
      updateFormState(withNewList);
    }
    if (setIsDirty) {
      setIsDirty(true);
    }
    if (setIsChanged) {
      setIsChanged(true);
    }
  };

  const handleDeleteItem = (index) => {
    const withNewList = {
      ...formState,
      list: formState?.list?.filter((item, listIndex) => listIndex !== index),
    };
    const newFormErrors = {
      ...formErrors,
      list: formErrors?.list?.filter((item, listIndex) => listIndex !== index),
    };
    updateFormErrors(newFormErrors);
    if (setIsDirty) {
      setIsDirty(true);
    }
    if (setIsChanged) {
      setIsChanged(true);
    }
    updateFormState(withNewList);

    if (withNewList?.max === null || withNewList?.max === '') {
      if (withNewList?.canSelectMultipleOptions) {
        const quantity = Number(withNewList?.list?.length) * Number(withNewList?.maxAnySingleModifierItems || 0);
        const newData = set(withNewList, 'maxTotalNumberOfModifierItems', quantity);
        updateFormState({ ...newData });
      }
    }
  };

  const handleSortEnd = (data) => {
    const { oldIndex, newIndex } = data;

    if (oldIndex !== newIndex) {
      const sortedList = arrayMove(formState.list, oldIndex, newIndex);
      updateFormState({ ...formState, list: sortedList });
      if (setIsDirty) {
        setIsDirty(true);
      }
      if (setIsChanged) {
        setIsChanged(true);
      }
    }
  };

  const handleChangeIsOnePrice = (e) => {
    const checked = e.target.checked;
    const formStateCopy = { ...formState, list: [...formState.list] };
    const newPriceOverride = [];

    formStateCopy.isOnePrice = !checked;
    // changeIsOnePrice(e.currentTarget.checked);
    if (connectedProviders) {
      Object.keys(connectedProviders).map((modItem) => {
        if (connectedProviders[modItem]) {
          newPriceOverride.push({
            context_type: CONTEXT_TYPES.provider,
            context_value: modItem,
            name: modItem,
            price: 0,
          });
        }
      });
    }
    if (!checked) {
      formStateCopy.list.forEach((listItem, index) => {
        formStateCopy.list[index].priceOverride = newPriceOverride;
      });
    } else {
      formStateCopy.list.forEach((listItem, index) => {
        const priceOverride = [...listItem.priceOverride];
        sizeTypeModifiers &&
          sizeTypeModifiers[0] &&
          sizeTypeModifiers[0].list.forEach((currentItem, itemIndex) => {
            const found = listItem.priceOverride.find((item) => {
              return item.context_value === currentItem._id;
            });
            if (!found) {
              priceOverride.push({
                context_type: 'MOD_ITEM',
                context_value: currentItem?._id,
                name: currentItem.name,
                price: 0,
                priceOverride: newPriceOverride,
              });
            }
          });
        listItem.priceOverride = priceOverride;
      });
    }
    updateFormState(formStateCopy);
  };

  const validateNumber = (value, name) => {
    const reg = /^\d+$/;
    ///^\d+(\.\d{1,2})?$/
    let copyFormErrors = { ...formErrors };
    if (reg.test(value) && value > 0) {
      copyFormErrors = set(formErrors, name, { error: false, message: '' });
      updateFormErrors({ ...copyFormErrors });
      return true;
    } else {
      if (value) {
        copyFormErrors = set(formErrors, name, { error: true, message: 'Please enter a valid number' });
        updateFormErrors({ ...copyFormErrors });
        return false;
      }
    }
  };

  const handleChangeField = ({ currentTarget }) => {
    let { value, name } = currentTarget;
    if (setIsDirty) {
      setIsDirty(true);
    }
    setTimeout(() => {
      history.replace({ pathName: history.location.pathName, state: 'changeMod', search: history.location.search });
    }, 0);
    if (name.includes('price')) {
      value = priceTransform(value);
    }
    let copyFormErrors = { ...formErrors };
    if (
      !value &&
      name !== 'max' &&
      name !== 'min' &&
      name !== 'description' &&
      name !== 'minTotalNumberOfModifierItems' &&
      name !== 'maxTotalNumberOfModifierItems' &&
      name !== 'minAnySingleModifierItems' &&
      name !== 'maxAnySingleModifierItems' &&
      name !== 'free'
    ) {
      copyFormErrors = set(formErrors, name, { error: true, message: t('field_is_required_') });
      updateFormErrors({ ...copyFormErrors });
    } else {
      copyFormErrors = set(formErrors, name, { error: false, message: '' });
      if (formState?.products && formState.list) {
        formState.list.forEach((listItem) => {
          formState.products.map((item) => {
            if (currentProduct?._id === item._id) {
              if (currentProduct?.connectedProviders[providers.ubereats.srv] && priceTransform(value) > 374) {
                copyFormErrors = set(formErrors, name, {
                  error: true,
                  message: t('price_$375.00_for_ubereats_'),
                });
              } else {
                copyFormErrors = set(formErrors, name, {
                  error: false,
                  message: '',
                });
              }
            }
          });
        });
      } else {
        if (formState.list && !isEdit) {
          formState.list.forEach((listItem) => {
            console.log('isEditlistItem: ', listItem);

            if (currentProduct?.connectedProviders[providers.ubereats.srv] && priceTransform(value) > 374) {
              copyFormErrors = set(formErrors, name, {
                error: true,
                message: t('price_$375.00_for_ubereats_'),
              });
            } else {
              copyFormErrors = set(formErrors, name, {
                error: false,
                message: '',
              });
            }
          });
        }
      }
    }

    let newData = set(formState, name, value);
    if (setIsDirty) {
      setIsDirty(true);
    }
    if (setIsChanged) {
      setIsChanged(true);
    }
    setChanged(true);
    if (
      NAME_ENUMS.minQ === name ||
      NAME_ENUMS.maxQ === name ||
      NAME_ENUMS.min === name ||
      NAME_ENUMS.max === name ||
      NAME_ENUMS.minT === name ||
      NAME_ENUMS.maxT === name ||
      NAME_ENUMS.minAnySingle === name ||
      NAME_ENUMS.maxAnySingle === name ||
      NAME_ENUMS.free === name
    ) {
      if (validateNumber(value, name)) {
        validationModifierCustomersCan(formState, copyFormErrors, formErrors, updateFormErrors, name);
      } else {
        if (name === NAME_ENUMS.minAnySingle && !value) {
          copyFormErrors = set(formErrors, name, { error: true, message: `menu.min_number` });
        }
        if (name === NAME_ENUMS.maxAnySingle && !value) {
          copyFormErrors = set(formErrors, name, { error: true, message: `menu.min_number` });
        }
        updateFormErrors({ ...copyFormErrors });
      }
    }
    newData?.list?.forEach((item) => {
      item.price = Number(item.price);
    });
    updateFormState({ ...newData });
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const allowedKeyCodes = [46, 8, 9, 27, 13, 110, 107, 109];
    if (allowedKeyCodes.indexOf(keyCode) === -1 && (e.shiftKey || keyCode < 48 || keyCode > 57)) {
      e.preventDefault();
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Box>
          {mode === 'edit' && isInOverview && products?.length ? (
            <Stack direction="row" alignItems="center" sx={{ py: 1, px: 2, background: '#DCE8FF', mb: 3, mt: 2 }}>
              <InfoOutlinedIcon />

              <Typography sx={{ ml: 2 }}>{t('menu.this_group_attached_applied_everywhere')}</Typography>
            </Stack>
          ) : null}
          <Grid container xl={isInOverview ? 12 : 6} flexDirection="column">
            <Grid item xl={isInOverview ? 8 : 5}>
              <Box onClick={(event) => handleClickPopover(event, 'name', 'batch_report.name', name)}>
                <TextField
                  onChange={handleChangeField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={name}
                  size="small"
                  sx={{ width: '100%', mt: 2 }}
                  label={t('Name')}
                  name="name"
                  error={formErrors?.name?.error}
                  helperText={t(formErrors?.name?.message)}
                  InputProps={{
                    endAdornment: isEdit ? (
                      <LocalizationPopover
                        id={_id}
                        field="name"
                        label={currentSelectedLabel}
                        required={true}
                        values={currentSelectedValues}
                        open={open}
                        handleClose={handleClose}
                        languageLimit={languageLimit}
                        currentSelectedField={currentSelectedField}
                        handleChangeField={handleChangeField}
                        isModifier={true}
                      />
                    ) : null,
                    inputRef: inputRef,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={isInOverview ? 8 : 5}>
              <Box onClick={(event) => handleClickPopover(event, 'title', 'settings.internal_name', title)}>
                <TextField
                  onChange={handleChangeField}
                  value={title}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: '100%', mt: 2 }}
                  label={t('settings.internal_name')}
                  name="title"
                  error={formErrors?.title?.error}
                  helperText={formErrors?.title?.message}
                  InputProps={{
                    endAdornment: isEdit ? (
                      <LocalizationPopover
                        id={_id}
                        field="title"
                        label={currentSelectedLabel}
                        required={true}
                        values={currentSelectedValues}
                        open={open}
                        handleClose={handleClose}
                        languageLimit={languageLimit}
                        currentSelectedField={currentSelectedField}
                        handleChangeField={handleChangeField}
                        isModifier={true}
                      />
                    ) : null,
                    inputRef: internalRef,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={isInOverview ? 8 : 12}>
              <Box onClick={(event) => handleClickPopover(event, 'description', 'schedule.description', description)}>
                <TextField
                  onChange={handleChangeField}
                  size="small"
                  sx={{ width: '100%', mt: 2 }}
                  multiline={true}
                  name="description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t('Description')}
                  type="textarea"
                  rows="3"
                  value={description}
                  InputProps={{
                    endAdornment: isEdit ? (
                      <LocalizationPopover
                        id={_id}
                        field="description"
                        label={currentSelectedLabel}
                        required={false}
                        values={currentSelectedValues}
                        open={open}
                        handleClose={handleClose}
                        languageLimit={languageLimit}
                        currentSelectedField={currentSelectedField}
                        handleChangeField={handleChangeField}
                        isModifier={true}
                      />
                    ) : null,
                    inputRef: textAreaRef,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mt: 3 }}>
            <Grid item xs={'auto'}>
              <Typography sx={{ color: '#3D4350', fontWeight: 500 }}>{t('menu.modifier_options_price')}</Typography>
            </Grid>
            <Grid item xs sx={{ ml: 3 }}>
              <Divider />
            </Grid>
          </Grid>
          <Stack direction="row" sx={{ my: 3 }}>
            {!isInModifierGroupPage && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={currentProduct?.isOnePrice || mode === 'edit'}
                      onChange={handleChangeIsOnePrice}
                      checked={currentProduct?.isOnePrice ? false : !isOnePrice}
                      name="isOnePrice"
                    />
                  }
                  label={t('menu.modifier_prices_vary_size')}
                />
              </FormGroup>
            )}
          </Stack>
          <Stack sx={{ width: 'max-content', mb: 2 }}>
            <Button
              id="modifierFormAddOption"
              startIcon={<AddIcon />}
              sx={{ background: '#F2F2F2', color: '#333333' }}
              onClick={handleAddItem}
            >
              {t('menu.add_option')}
            </Button>
          </Stack>
          <Grid item xl={isInOverview ? 12 : 6}>
            <Suspense fallback={<Preloader />}>
              <SortableContainerList
                handleSortEnd={handleSortEnd}
                fields={list}
                mode={mode}
                isOnePrice={isOnePrice}
                connectedProviders={connectedProviders}
                total={list?.length}
                sizeTypeModifiers={sizeTypeModifiers}
                onDelete={handleDeleteItem}
                isInOverview={isInOverview}
                formState={formState}
                handleChangeField={handleChangeField}
                formErrors={formErrors}
                updateFormState={updateFormState}
              />
            </Suspense>
          </Grid>
          <Box sx={{ mt: 3 }} className="customers-can-section">
            <Box sx={{ mt: 3 }}>
              <Grid container alignItems="center">
                <Grid item xs={'auto'}>
                  <Typography sx={{ color: '#3D4350', fontWeight: 500 }}>{t('menu.customers_can')}</Typography>
                </Grid>
                <Grid item xs sx={{ ml: 3 }}>
                  <Divider />
                </Grid>
              </Grid>
              <>
                <Stack direction="row" sx={{ mt: 2, mb: 1, height: 65 }}>
                  <TextField
                    sx={{ width: '200px' }}
                    label={t('menu.select_least')}
                    name={`min`}
                    size="small"
                    onWheel={onWheelPreventChangeNumberField}
                    value={min}
                    InputLabelProps={labelProps}
                    onChange={handleChangeField}
                    onKeyPress={handleKeyPress}
                    error={formErrors?.min?.error}
                    helperText={
                      formErrors?.min?.error
                        ? !Number(min)
                          ? `${t('menu.min_number')}`
                          : Number(min) > 100000
                            ? t('menu.max_number')
                            : `${t(formErrors?.min?.message?.split(' ')[0])} ${formErrors?.min?.message?.split(' ')[1]}`
                        : ''
                    }
                  />
                  <TextField
                    sx={{ ml: 1, width: '200px' }}
                    label={t('menu.select_most')}
                    name={`max`}
                    onWheel={onWheelPreventChangeNumberField}
                    size="small"
                    value={max}
                    InputLabelProps={labelProps}
                    onChange={handleChangeField}
                    onKeyPress={handleKeyPress}
                    error={formErrors?.max?.error}
                    helperText={
                      formErrors?.max?.error
                        ? !Number(max)
                          ? `${t('menu.min_number')}`
                          : Number(max) > 100000
                            ? t('menu.max_number')
                            : `${t(formErrors?.max?.message?.split(' ')[0])} ${formErrors?.max?.message?.split(' ')[1]}`
                        : ''
                    }
                  />
                  <TextField
                    sx={{ ml: 1, width: '200px' }}
                    label={t('menu.free_options')}
                    onWheel={onWheelPreventChangeNumberField}
                    name={`free`}
                    size="small"
                    value={free}
                    InputLabelProps={labelProps}
                    onChange={handleChangeField}
                    onKeyPress={handleKeyPress}
                    error={formErrors?.free?.error}
                    helperText={
                      formErrors?.free?.error
                        ? !Number(free)
                          ? `${t('menu.min_number')}`
                          : Number(free) > 100000
                            ? t('menu.max_number')
                            : `${t(formErrors?.free?.message?.split(' ')[0])} ${formErrors?.free?.message?.split(' ')[1]}`
                        : ''
                    }
                  />

                  <Tooltip title={t('menu.free_options_add_price')} followCursor>
                    <ErrorOutlineIcon sx={{ ml: 2 }} />
                  </Tooltip>
                </Stack>
                <MinAndMaxOptions
                  handleChangeMultipleOptions={handleChangeMultipleOptions}
                  canSelectMultipleOptions={canSelectMultipleOptions}
                  minAnySingleModifierItems={minAnySingleModifierItems}
                  labelProps={labelProps}
                  handleChangeField={handleChangeField}
                  formErrors={formErrors}
                  maxAnySingleModifierItems={maxAnySingleModifierItems}
                  minTotalNumberOfModifierItems={minTotalNumberOfModifierItems}
                  handleChangeFieldMinTotalNumberOfModifierItems={handleChangeFieldMinTotalNumberOfModifierItems}
                  maxTotalNumberOfModifierItems={maxTotalNumberOfModifierItems}
                  handleChangeFieldMaxTotalNumberOfModifierItems={handleChangeFieldMaxTotalNumberOfModifierItems}
                />
              </>
              <Box sx={{ mt: 3 }}>
                <Grid container alignItems="center">
                  <Grid item xs={'auto'}>
                    <Typography
                      sx={{
                        color: '#3D4350',
                        fontWeight: 500,
                      }}
                    >
                      {t('menu.connected_items_list')}
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ ml: 3 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Stack direction="row" sx={{ mt: 2, flexWrap: 'wrap' }}>
                  {products?.map((product) => (
                    <Box
                      key={product._id}
                      variant="contained"
                      sx={{
                        background: '#CFDFFF',
                        border: 'none',
                        color: 'black',
                        mr: 1,
                        mt: 2,
                        padding: '8px 12px',
                        borderRadius: '3px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {product.name}
                    </Box>
                  ))}
                </Stack>
              </Box>

              {isInOverview && (
                <Typography sx={{ fontSize: '13px', mt: 3 }}>
                  {t('menu.modifier_templates_are_going_editing_items_you_want')}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

// const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

export default memo(ModifierGroupForm);
