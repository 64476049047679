import React from 'react';
import { HeaderContainer, StripedDataGrid } from '../styles';
import { Box, Button } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SectionInfoIcon from 'assets/images/SectionInfoIcon.png';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import InformationModal from 'pages/TableOrdering/Tables/InformationModal';
import CreateOrEditForm from 'pages/TableOrdering/Tables/CreateOrEditForm';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTableOrderingTable } from 'pages/TableOrdering/hooks/useTableOrderingTable';

const Tables = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleCancel,
    formMethods,
    tableColumns,
    tableFilter,
    loading,
    open,
    setOpen,
    mode,
    sections,
    isSectionEmpty,
    openInfoModal,
    handleCloseInfoModal,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedTable,
  } = useTableOrderingTable();
  return (
    <Box>
      <ConfirmPromptModal
        handleConfirm={formMethods.handleSubmit(handleSubmit)}
        submitData={formMethods.getValues()}
        hasUnsavedChanges={formMethods.formState.isDirty}
        formState={formMethods}
      />
      <InformationModal
        open={openInfoModal}
        handleClose={handleCloseInfoModal}
        icon={SectionInfoIcon}
        iconName={'action info'}
        title={'Action Required'}
        description={'Please create a section first. Navigate to the “Sections” tab to create a new section.'}
      />

      <HeaderContainer>
        <Button
          id="tablesAddTable"
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          type="button"
          disabled={open || (!sections?.data?.length && isSectionEmpty)}
          className={`${open ? 'disabledButton' : ''}`}
          startIcon={<AddOutlinedIcon />}
        >
          {t('table_ordering.add_table')}
        </Button>
      </HeaderContainer>
      <TableOrderModal
        open={open}
        handleClose={handleCancel}
        title={mode === MODE.CREATE ? t('table_ordering.add_table') : t('table_ordering.edit_table')}
        disabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
        saveTitle={mode === MODE.CREATE ? t('table_ordering.add_table') : t('table_ordering.save')}
        cancelTitle={'Cancel'}
        formMethods={formMethods}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <CreateOrEditForm />
      </TableOrderModal>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        getRowClassName={(params) => (params?.row?.id === lastCreatedTable ? 'highlight_last-selected_item' : '')}
        hideFooter={true}
        isEditable={false}
        rowsPerPageOptions={[100]}
        pageSizeOptions={[100]}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rows={tableFilter}
        columns={tableColumns}
        components={{
          LoadingOverlay: Preloader,
        }}
        loading={loading}
        localeText={{ noRowsLabel: t('table_ordering.empty_table') }}
      />
    </Box>
  );
};

export default Tables;
