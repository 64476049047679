import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';

import { getAvailabilitySettings, deleteAvailability } from 'redux/actions/availability';
import Preloader from 'components/Preloader';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { Box, Paper } from '@mui/material';

const Schedule = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAvailabilitySettings()
      .then((data) => {
        setAvailability(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [history]);

  const handleDelete = async (data) => {
    const confirmed = await confirm({
      title: 'confirm_modal.schedule',
      message: t('schedule.are_you_schedule'),
      cancelText: t('general.cancel'),
      confirmText: t('settings.remove'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      deleteAvailability(data._id).then(() => setAvailability((prev) => prev.filter((i) => i._id !== data._id)));
    }
  };

  const handleEdit = async (id) => {
    return history.push(`/schedule/new/${id}`);
  };

  return (
    <ErrorBoundaryWrapper>
      <Paper className="order-content" >
        <Box sx={{ p: 2 }}>
          <Private permission="SCHEDULE_EDIT">
            <Box className="text-right" sx={{ mb: 2 }}>
              <Button id="scheduleCreate" tag={Link} to={'/schedule/new'} color="primary">
                {t('Add New Schedule')}
              </Button>
            </Box>
          </Private>


          <Table className="table-vertical-middle" striped>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Type')}</th>
                <th>{t('Description')}</th>
                <th>{t('Business')} </th>
                <th>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {availability.length ? (
                availability.map((item) => (
                  <tr key={item._id}>
                    <td className="text-capitalize">{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.description}</td>
                    <td>{item.business?.companyName}</td>
                    <td>
                      <Private permission="SCHEDULE_EDIT">
                        <Button id="scheduleId" color="link" onClick={() => handleEdit(item._id)}>
                          <i className="icon icon-edit"></i>
                        </Button>
                        <Button id="scheduleItem" color="link" onClick={() => handleDelete(item)}>
                          <i className="icon icon-trash-2"></i>
                        </Button>
                      </Private>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center text-muted p-5">
                    {loading ? <Preloader /> : t('order_view.empty_list')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
      </Paper>
    </ErrorBoundaryWrapper>
  );
};

export default Schedule;
