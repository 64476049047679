import React, { useMemo } from 'react';
import 'date-fns';
import { handleTypeName, objEnum } from './utils';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import ExpoReport from 'assets/icons/report-export.svg';
import { convertCamelCaseToTitleCase } from 'helpers/convertCamelCaseToTitleCase';
import { extractUniqueKeys } from '../../redux/actions/reports';

const DETAILED_ORDER_REPORT_FIELDS = [
  'Brand Name',
  'Location Name',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip Code',
  'Order Number',
  'Be Ready Time',
  'Employee Id',
  'Employee Name',
  'Employee Last Name',
  'Employee Initials',
];

// CSV Format.

const TableReport = ({
  reportList,
  loadingExport,
  tableTypeName,
  currentReport,
  filterSubmitted,
  handleExport,
  loading,
  headerKeys,
}) => {
  const { t } = useTranslation();
  const handelRowData = (reportList, headerKeysData) => {
    const numberFields = [
      'total',
      'sub_total',
      'tax',
      'tip',
      'delivery',
      'total_sales',
      'total_amount',
      'average_sale',
      'total_tax',
      'total_tips',
      'total_discounts',
      'subtotal_sales',
      'total_delivery',
      'driver_tip',
      'kitchen_tip',
      'total_driver_tips',
      'total_driver_tip',
      'total_kitchen_tips',
      'total_kitchen_tip',
      'total_coupon',
      'coupon',
      'total_delivery_fee',
    ];
    let headers = [];
    let rows = reportList;
    if (Array.isArray(headerKeysData) && !headerKeysData?.length && reportList && reportList[0]) {
      headerKeysData = extractUniqueKeys(reportList);
    }
    for (let hName of headerKeysData) {
      const headerName = convertCamelCaseToTitleCase(hName.replaceAll('_', ' '));
      headers.push({
        field: hName,
        headerName: t(headerName),
        hide: Boolean(
          transformTableType === t(`${objEnum.DETAILED_ORDER_REPORT}`) &&
            DETAILED_ORDER_REPORT_FIELDS.includes(headerName)
        ),
        flex: 1,
        renderCell: ({ value, field }) => {
          if (field === 'reason') {
            return (
              <Typography
                fontSize="14px"
                className={'report--vertical'}
                textAlign={numberFields.includes(field) ? 'right' : 'left'}
              >
                {value}
              </Typography>
            );
          } else {
            return (
              <Typography fontSize="14px" textAlign={numberFields.includes(field) ? 'right' : 'left'} width="100%">
                {value}
              </Typography>
            );
          }
        },
      });
    }
    // for (let hName in reportList[0]) {
    //   const headerName = convertCamelCaseToTitleCase(hName.replaceAll('_', ' '));
    //   headers.push({
    //     field: hName,
    //     headerName: t(headerName),
    //     flex: 1,
    //     renderCell: ({ value, field }) => {
    //       if (field === 'reason') {
    //         return (
    //           <Typography
    //             fontSize="14px"
    //             className={'report--vertical'}
    //             textAlign={numberFields.includes(field) ? 'right' : 'left'}
    //           >
    //             {value}
    //           </Typography>
    //         );
    //       } else {
    //         return (
    //           <Typography fontSize="14px" textAlign={numberFields.includes(field) ? 'right' : 'left'} width="100%">
    //             {value}
    //           </Typography>
    //         );
    //       }
    //     },
    //   });
    // }
    return { headers, rows };
  };

  const [pageSize, setPageSize] = React.useState(100);

  const tableType = currentReport?.type || tableTypeName;
  const tableTypeUp = tableType?.toUpperCase() || '';
  const transformTableType = t(handleTypeName(tableTypeUp));

  const rowData = useMemo(() => {
    return handelRowData(reportList, headerKeys);
  }, [reportList, t, headerKeys]);

  const { headers, rows } = rowData;
  if (!reportList.length) {
    return (
      <Box sx={{ my: 4 }}>
        <EmptyMenu text={filterSubmitted && !loading ? t('reports_settings.there_is_no_data_filters') : ''} />
      </Box>
    );
  }
  return (
    <div className="report-list">
      <div className="mt-3" />
      <div className="">
        {/* //Will implement in Future Update  */}
        <div className="row">
          <div className=" col-12">
            <div className="d-flex justify-content-between group-btn pb-3">
              <div className="">
                <h5 className="">
                  {/* {handleTypeName(tableType) || tableType + '(' + handleTypeName(currentFilter?.data.type) + ')'} */}
                  {transformTableType}
                </h5>
              </div>
              {/* Btn Extension */}
              <Button
                id="tableExport"
                disabled={loadingExport}
                color="primary"
                className="banner-btn banner-filter"
                onClick={handleExport}
              >
                {loadingExport ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <>
                    <img src={ExpoReport} alt="REPORT-EXPORT" />
                    <span className="ml-1">{t('reports_settings.export')}</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
        <Box
          sx={{
            my: 2,
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
            '& .MuiDataGrid-row	': {
              '&:nth-of-type(odd)': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            },
            '& .MuiDataGrid-sortIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-filterIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-menuIcon	': {
              '& .MuiButtonBase-root': { color: 'white' },
            },
            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              margin: 0,
            },
          }}
        >
          <DataGrid
            getRowId={(row) => uuidv4()}
            autoHeight={true}
            pageSize={pageSize}
            // hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            columns={headers}
            rows={rows}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            // pageSize={5}
            // rowsPerPageOptions={[5]}
          />
        </Box>
      </div>
    </div>
  );
};

export default TableReport;
