// ConfirmModal.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

/**
 * Opens a confirmation modal and returns a promise that resolves based on user action.
 *
 * @param {Object} props - Props to customize the confirmation modal.
 * @returns {Promise} - Resolves with user's action (e.g., true for confirm, false for cancel).
 */
export const confirm = (props) => {
  return new Promise((resolve) => {
    const el = document.createElement('div');
    document.body.appendChild(el); // Append to DOM

    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el.remove(); // Clean up the DOM
      resolve(result);
    };

    render(
      <ConfirmModal
        {...props}
        onDiscard={handleResolve}
        onClose={handleResolve}
      />,
      el
    );
  });
};

/**
 * Confirmation Modal Component.
 *
 * @param {Object} props - Component props.
 * @returns {JSX.Element} - Rendered modal.
 */
const ConfirmModal = ({
  title = 'confirm_modal.title',
  onClose,
  onDiscard = () => { },
  message = 'confirm_modal.message',
  cancelText = 'confirm_modal.cancel',
  confirmText = 'confirm_modal.save_and_leave',
  discardText = 'confirm_modal.discard',
  hideCloseIcon = false,
  hideDiscardBtn = true,
  open = true,
  URL = '',
  background = '#1976d2',
}) => {
  const { t } = useTranslation();
  const [modalOpenState, setModalOpenState] = useState(open);

  const handleClose = (val) => {
    setModalOpenState(false);
    if (typeof val === 'boolean') {
      onClose(val);
    }
  };

  const handleDiscard = () => {
    setModalOpenState(false);
    onDiscard('discard');
  };

  const redirectUrl = (path) => window.location.replace(path);

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={modalOpenState}
      sx={{ '.MuiPaper-elevation': { minWidth: '500px' } }}
      maxWidth="lg"
    >
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: 400,
          textAlign: 'center',
          color: '#3D4350',
          mt: 3,
          pl: 3,
          pr: 3,
        }}
      >
        {t(title)}
        {!hideCloseIcon && (
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 12 }}
            aria-label="close"
            onClick={() => handleClose(false)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Typography>
      <DialogTitle sx={{ p: 0, pl: 3, pr: 3 }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 400, color: '#49454F', mt: 1 }}>
          {t(message)}
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          display: 'flex',
          mt: 3,
          pt: 2,
          pb: 2,
          pl: 3,
          pr: 3,
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          id="confirmCancelText"
          sx={{ borderRadius: '6px', ml: 2, color: '#626A7A', fontSize: 14, fontWeight: 500 }}
          onClick={() => (URL ? redirectUrl(URL) : handleClose(false))}
        >
          {t(cancelText)}
        </Button>
        {!hideDiscardBtn && (
          <Button
            id="confirmDiscardText"
            onClick={handleDiscard}
            sx={{
              borderRadius: '6px',
              background: 'none',
              color: '#626A7A',
              fontSize: 14,
              border: '1px solid rgba(98, 106, 122, 0.50)',
              boxShadow: 0,
              '&:hover': {
                backgroundColor: 'rgb(238 238 238 / 50%)',
                boxShadow: 0,
              },
            }}
            variant="contained"
          >
            {t(discardText)}
          </Button>
        )}
        <Button
          id="confirmTextModal"
          onClick={() => handleClose(true)}
          sx={{
            borderRadius: '6px',
            boxShadow: 0,
            fontSize: 14,
            border: `1px solid ${background}`,
            background: background,
            '&:hover': {
              background: background,
            },
          }}
          variant="contained"
        >
          {t(confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDiscard: PropTypes.func,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  discardText: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  hideDiscardBtn: PropTypes.bool,
  open: PropTypes.bool,
  URL: PropTypes.string,
  background: PropTypes.string,
};

ConfirmModal.defaultProps = {
  onDiscard: () => { },
  title: 'confirm_modal.title',
  message: 'confirm_modal.message',
  cancelText: 'confirm_modal.cancel',
  confirmText: 'confirm_modal.save_and_leave',
  discardText: 'confirm_modal.discard',
  hideCloseIcon: false,
  hideDiscardBtn: true,
  open: true,
  URL: '',
  background: '#1976d2',
};

export default ConfirmModal;
