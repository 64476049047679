import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { MAX_ITEM_COUNT_IN_TABLE, MODE } from 'pages/TableOrdering/config';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  createEmployeesTO,
  deleteEmployeesTO,
  getEmployeesOrderingTO,
  updateEmployeesTO,
} from 'redux/actions/TableOrdering/employees';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LAST_CREATED_EMPLOYEE_TO } from 'redux/actions/types';
import IconButton from '@mui/material/IconButton';
export const employeeSchema = yup.object().shape({
  firstname: yup.string().required('This field is required'),
  lastname: yup.string().required('This field is required'),
  role: yup.string().required('This field is required'),
  pinCode: yup
    .string()
    .matches(/^\d{4}$/, 'Enter 4 digit code')
    .required('This field is required'),
});
const defaultValues = {
  firstname: '',
  lastname: '',
  pinCode: '',
  role: 'Waiter',
};

export const capitalizeName = (name) => {
  return name
    .split(' ') // Split the name into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a single string
};

export const employeeJobs = [{ _id: 1, name: 'Waiter' }];

export const useTableOrderingEmployee = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(MODE.CREATE);
  const [showPinCode, setShowPinCode] = useState(false);
  const { employees = [], loading, lastCreatedEmployee } = useSelector(({ tableOrdering }) => tableOrdering);
  const { currentBusiness } = useSelector(({ business }) => business);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_TABLE);
  const formMethods = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(employeeSchema),
  });
  const visiblePinCode = () => setShowPinCode((prevState) => !prevState);
  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getEmployeesOrderingTO());
    }
  }, [currentBusiness?._id, location]);

  useEffect(() => {
    return () => {
      dispatch({ type: LAST_CREATED_EMPLOYEE_TO, payload: null });
    };
  }, []);

  const handleOpen = () => setOpen(true);

  const deleteEmployee = async (data) => {
    const confirmed = await confirm({
      title: t('Confirm Employee Deletion'),
      message: t('Are you sure you want to delete this employee?'),
      cancelText: t('Cancel'),
      confirmText: t('Delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    });
    if (confirmed) {
      dispatch(deleteEmployeesTO(data?.id));
    }
  };
  const edit = (data) => {
    setOpen(true);
    formMethods.reset(data);
    setMode(MODE.EDIT);
  };
  const employeeColumns = [
    {
      field: 'firstname',
      headerName: t('First Name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'lastname',
      headerName: t('Last Name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'role',
      headerName: t('Job Title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => edit(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => deleteEmployee(params?.row)}>
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const employeeFilter = employees.map((employ) => ({
    id: employ._id,
    firstname: employ.firstname || '',
    lastname: employ.lastname || '',
    pinCode: employ.pinCode || '',
    role: capitalizeName(employ.role) || '',
  }));
  const updateEmployee = (values) => {
    const requestData = {
      businessId: currentBusiness?._id || '',
      employeeId: values.id,
      body: {
        firstname: values.firstname || '',
        lastname: values.lastname || '',
        role: values.role?.toLowerCase() || '',
        pinCode: values.pinCode,
      },
    };
    formMethods.clearErrors();
    dispatch(updateEmployeesTO(requestData)).then(() => {
      handleCancel();
    });
  };

  const createEmployee = (values) => {
    const requestData = {
      ...values,
      role: values.role?.toLowerCase(),
    };
    formMethods.clearErrors();
    dispatch(createEmployeesTO(requestData)).then(() => {
      handleCancel();
    });
  };
  const handleSubmit = (values) => {
    open && mode === MODE.EDIT ? updateEmployee(values) : createEmployee(values);
  };

  const handleCancel = () => {
    formMethods.reset(defaultValues);
    formMethods.clearErrors();
    setOpen(false);
    setShowPinCode(false);
    setTimeout(() => {
      setMode(MODE.CREATE);
    }, 300);
  };
  return {
    open,
    mode,
    handleCancel,
    formMethods,
    handleSubmit,
    employeeFilter,
    employeeColumns,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedEmployee,
    handleOpen,
    loading,
    visiblePinCode,
    showPinCode,
  };
};
