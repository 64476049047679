import React, { useMemo, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import Preloader from 'components/Preloader';
import StyledDataGrid from 'components/DataGridTable';
import useQuery from 'hooks/useQuery';
import { getModifiersAll } from 'redux/actions/Menu/modifiers';
import FormModifierPage from './FormModifierPage';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';

const Products = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();
  const entityType = query.get('entityType');
  const mode = query.get('mode');
  const mid = query.get('mid');
  const [pageSize, setPageSize] = useState(5);

  const {
    menu: { modifiersAll, currentModifier },
    loading,
  } = useSelector(({ menu }) => menu);
  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      sortable: false,
      filterable: true,
      editable: false,
      renderCell: ({ row }) => {
        const { name, id } = row;
        return (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              history.push({
                search: `?entityType=modifier&mid=${id}&mode=edit`,
              });
            }}
          >
            <Typography
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                  textUnderlineOffset: '3px',
                },
              }}
            >
              {name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'title',
      headerName: t('menu.internal_name'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
    },
    {
      field: 'isOnePrice',
      headerName: t('Type'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: true,
      renderCell: ({ value }) => {
        return value ? 'One Size' : 'Multi Size';
      },
    },
    {
      field: 'createdAt',
      headerName: t('menu.created_at'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      renderCell: ({ value }) => {
        return value ? moment(value).format('MM-DD-YYYY') : '';
      },
    },
    {
      field: 'updatedAt',
      headerName: t('menu.updated_at'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      renderCell: ({ value }) => {
        return value ? moment(value).format('MM-DD-YYYY') : '';
      },
    },
  ]);

  useEffect(() => {
    dispatch(getModifiersAll());
  }, [mode]);

  const handlePageChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const addNewGroup = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?entityType=modifier&mid=${uuidv4()}&cid=${uuidv4()}&mode=create`,
    });
  };

  if (entityType) {
    return <FormModifierPage currentModifier={currentModifier} />;
  }
  if (loading) {
    return <Preloader overlay />;
  }
  return (
    <Grid container spacing={2}>
      {/*{true && <Preloader overlay />}*/}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
          <Typography variant="h5">{t('menu.modifiers')}</Typography>
          <Private permission="MENU_EDIT">
            <Button id="modifiersGroup" size="small" variant="outlined" onClick={addNewGroup}>
              + {t('menu.new_group')}
            </Button>
          </Private>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2 }}>
          <StyledDataGrid
            autoHeight={true}
            // hideFooterPagination={true}
            loading={!modifiersAll}
            components={{ LoadingOverlay: Preloader }}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={modifiersAll}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={handlePageChange}
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Products;
