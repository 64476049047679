// src/utils/printUtils.js

/**
 * Function to print a specific element by its ID.
 * @param {string} elementId - The ID of the element to print.
 * @param {object} [options] - Optional configurations.
 * @param {object} [options.sx] - Styles to override the element's styles.
 */
export const printElement = (elementId, options = {}) => {
    const { sx = {} } = options;
    const element = document.getElementById(elementId);

    if (!element) {
        console.error(`Element with id "${elementId}" not found.`);
        return;
    }

    // Clone the element to avoid modifying the original
    const clonedElement = element.cloneNode(true);

    // Apply sx styles if provided
    Object.keys(sx).forEach((key) => {
        clonedElement.style[key] = sx[key];
    });

    // Get all the stylesheets
    const stylesheets = Array.from(document.styleSheets)
        .map((sheet) => {
            try {
                return Array.from(sheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join('\n');
            } catch (e) {
                // Ignore cross-origin stylesheets
                return '';
            }
        })
        .join('\n');

    // Create a new window for printing
    const printWindow = window.open('', '_blank', 'width=800,height=600');

    if (!printWindow) {
        console.error('Unable to open print window.');
        return;
    }

    // Write the cloned element and styles to the new window
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
        <title>Print Preview</title>
        <style>
          ${stylesheets}
        </style>
      </head>
      <body>
        ${clonedElement.outerHTML}
      </body>
    </html>
  `);
    printWindow.document.close();

    // Wait for content to load, then print
    printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        // printWindow.close();
    };
};
